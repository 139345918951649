footer {
  .logo {
    img {
      width: 25px;
      opacity: .3;
    }
  }

  .copy {

    margin-top: 0px;

    .container12 {

      display: block;

    }

  }

}
