i.fa {
    &.warning {
      color: #f3c202;
    }

    &.danger {
        color: #de2d2d;
    }

    &.success,
    &.finish,
    &.complete, {
      color: #00bc9a;
    }
}

ul.template-category-menu {
    li {
        padding: 0px;
        text-align: left;
        font-size: 14px;
        background-color: white;
        border: 1px solid #ededed;
        border-top: 0px;

        a {
            div {
                padding: 12px 0px;
                color: #7c7c7c;
                margin-left: 10px;
            }
        }

        &.template-category-menu-title {
            padding: 13px 0px;
            padding-left: 14px;
            background-color: #103c62;
            border: 1px solid #103c62;
            color: white;
            font-weight: 700;
        }

        &.selected {
            > a {
                > div {
                    color: #1b74b6;
                }
            }
        }

        &.selected,
        &.indirect-selected {
            > a {
                > div {
                    font-weight: bold;
                }
            }
        }

        ul.template-category-submenu {
            li {
                padding-left: 14px;
                border: 0px;
                background-color: #ededed;
            }
        }
    }
}