.switch {

  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;

  .slider {

    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &:before {

      position: absolute;
      content: "";
      height: 18px;
      width: 19px;
      border-radius: 50%;
      left: 2px;
      bottom: 1px;
      background-color: #fff;
      -webkit-transition: .4s;
      transition: .4s;

    }

  }

  input:checked + .slider {
    background-color: $blue;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
  }


}
