register-modal.bg-modal,
.float-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 999999999999999999;
	background: rgba(0, 0, 0, .8);
}

.bg-modal:before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.window-modal {
	width: 30%;
	background: #fff;
	border: 1pt solid #e1e1e1;
	position: absolute;
	left: 50%;
	top: 50%;
	opacity: 0;
	padding: 40px 0;
	-webkit-transform: translate(-50%,-50%) scale(2);
	-moz-transform: translate(-50%,-50%) scale(2);
	-ms-transform: translate(-50%,-50%) scale(2);
	-o-transform: translate(-50%,-50%) scale(2);
	transform: translate(-50%,-50%) scale(2);
	box-shadow: 0 5px 9px rgba(0, 0, 0, 0.5);
	-webkit-animation: animWindow 0.5s ease-in-out forwards;
	-moz-animation: animWindow 0.5s ease-in-out forwards;
	-ms-animation: animWindow 0.5s ease-in-out forwards;
	-o-animation: animWindow 0.5s ease-in-out forwards;
	animation: animWindow 0.5s ease-in-out forwards;
}

.float-modal {
	padding-top: 150px;
	overflow: auto;

	.close {
		color: #fff;
		font-size: 15px;
		float: right;
		margin-bottom: 10px;
		position: absolute;
		top: -25px;
		right: 0;
	}

	.float-modal-content {
		background: #fff;
		padding-top: 40px;
		padding-bottom: 40px;
		position: relative;
		width: 100% !important;
		top: 0 !important;
		transform: none !important;
	}
}
@-webkit-keyframes animWindow {
	0% {
		opacity: 0;
		-webkit-transform: translate(-50%,-50%) scale(2);
	}

	100% {
		opacity: 1;
		-webkit-transform: translate(-50%,-50%) scale(1);
	}
}
@-moz-keyframes animWindow {
	0% {
		opacity: 0;
		-moz-transform: translate(-50%,-50%) scale(2);
	}

	100% {
		opacity: 1;
		-moz-transform: translate(-50%,-50%) scale(1);
	}
}
@-ms-keyframes animWindow {
	0% {
		opacity: 0;
		-ms-transform: translate(-50%,-50%) scale(2);
	}

	100% {
		opacity: 1;
		-ms-transform: translate(-50%,-50%) scale(1);
	}
}

.content-modal,
.title-modal {
	width: 100%;
	float: left;
	padding: 20px;
	text-align: center;
	display: inline-block;
}

.title-modal {
	color: #333;
	padding: 10px;
	font-weight: bold;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
}

.window-modal .close-modal {
	position: absolute;
	top: -30px;
	right: -15px;
	width: 100px;
	height: 30px;
	z-index: 999;
	text-align: center;
	line-height: 18px;
	color: #fff;
	font-family: 'Museo_Sans_500';
}

.window-modal .close-modal:hover {
	text-shadow: 0 0 9px #fff;
}

.window-modal .close-modal:before {
	content: 'X Fechar';
}

.modal-shortlist {
	width: 740px;
}
// Modal cancel

.modal-cancel {
	textarea {
		height: 130px;
		border: 1px solid #ccc;
		outline: none;
		resize: vertical;
	}

	.title {
		padding-bottom: 10px !important;
	}
}

.modal-cotacao {
	&.float-modal {
		.close {
			color: #332828;
			right: 17px;
		}
	}
}

.modal {
	display: none;
	position: fixed;
	z-index: 999999;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
}

.modal-content {
	background-color: #fefefe;
	border: 1px solid #888;
	width: 45%;
	max-width: 800px;
	padding-top: 50px;
	padding-bottom: 50px;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 10px;
	transform: translate(-50%,-50%);
}

.modal-content-lg {
    width: 100%;
    max-width: 952px;
}

.close-modal {
	color: #aaaaaa;
	font-size: 40px;
	position: absolute;
	top: 0;
	right: 30px;
}

.close-modal:focus,
.close-modal:hover {
	color: #20405c;
	text-decoration: none;
	cursor: pointer;
}

.modal-text {
	width: 75%;
	margin: 0 auto;
}

#register-modal {
	.profiles {
		width: 100%;

		h1 {
			text-transform: uppercase;
		}

		p {
			color: #20405c;
			margin-top: 10px;
			margin-bottom: 0;
			font-family: $semibold;
		}

		.profile {
			display: inline-block;
			float: left;
			box-sizing: border-box;
			width: 33.3%;

			a {
				box-sizing: border-box !important;
				width: 200px;
				height: 200px;
				display: inline-block;
				padding: 60px;
				background: #f7f7f7;
				position: relative;

				&:after {
					content: '';
					border: 1pt solid #5b7183;
					width: 85%;
					opacity: 0;
					visibility: hidden;
					height: 85%;
					transition: 0.300s ease;
					position: absolute;
					top: 0;
					left: 0;
					margin: 13px;
				}

				&:hover {
					background: #20405c;

					i,
					p {
						color: #fff !important;
					}

					&:after {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			i {
				color: #20405c;
				font-size: 50px;
			}
		}
	}
}
@media only screen and (max-width: 1240px) {
	.modal-content {
		width: 80% !important;
	}
}

@media only screen and (max-width: 770px) {
	.modal-content {
		width: 90% !important;
		margin-top: 100px;
	}

	#register-modal {
		.profiles {
			.profile {

				&.vendor-profile {
					p {
						margin-left: -6px;
					}
				}

				a {
					width: 110px;
					height: 110px;
					padding: 20px;

					&:after {
						display: none;
					}
				}

				i {
					font-size: 32px;
				}
			}
		}
	}
}

@media only screen and (max-width: 400px) {
	.modal-content {
		margin-top: 165px;
	}
}