html {
	select {
		color: #fff !important;
	}

	select option {
		color: #000 !important;
	}

}

select {
	&.windows-detect {
		select option:first-child {
			color: #000 !important;
		}
	}
}

.footable-header th {
  text-align: left;
}

.project-user .multiselect__option span {
  color: $blue;
  padding: 10px 5px;
  display: inline-block;
}

.select {
.multiselect {
        box-sizing: content-box;
        display: block;
        position: relative;
        width: 100%;
        min-height: 40px;
        text-align: left;
        // color: #35495e;
    }

    .multiselect__tags {
        border: none;
        padding: 0;
    }

    .multiselect--active.multiselect--above .multiselect__input,
    .multiselect--active.multiselect--above .multiselect__tags {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .multiselect__select {
        display: none;
    }

    .multiselect__content-wrapper {
        position: absolute;
        display: block;
        background: #fff;
        width: 100%;
        overflow: auto;
        border: 1px solid #e8e8e8;
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 3;
        -webkit-overflow-scrolling: touch;
        height: 300%;

        &.multiselect--above {
            border-top: none;
        }

        .multiselect__content {
            display: block;
            width: 100%;

            .multiselect__element {

                .multiselect__option--highlight {
                    background: #e7e7e7;
                    outline: none;
                }

                .multiselect__option {
                    display: block;
                    padding: 12px;
                    min-height: 18px;
                    line-height: 16px;
                    text-decoration: none;
                    text-transform: none;
                    vertical-align: middle;
                    position: relative;
                    cursor: pointer;
                    white-space: nowrap;

                    .option__desc {
                        vertical-align: middle;
                    }

                    .option__small {
                        display: block;
                    }
                }
            }
        }
    }
}


.alert-icon {
  color: $blue_3;
}

.label-check {

  width: auto;
  display: inline-block;
  float: none;

}

label {
    float: left;
    width: 100%;
    color: $label-color;
    font-family: $heavy;
    font-size: 15px;
    padding-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.input {

        font-size: 14px !important;
        font-family: $semibold !important;
    }
}

div.select .selectize-control .selectize-input {

    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 47px !important;
    top: 0px;

}

.selectize-input {


    input {
        // width: 82px !important;
        height: 46px !important;
        top: 0px !important;
    }
}

select {
  cursor: pointer;
}

input,
select,
textarea {

    width: 100%;
    color: #949494;
    height: $input-height;
    box-shadow: inset 0 0 1px 1px #f4f4f4;
    border: 1px solid $gray_7;
    padding: 12px 10px;
    margin-bottom: 40px !important;
    outline: none;
    font-size: 16px;
    transition: .300s ease-in;
    border-radius: 0px !important;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset !important;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #d7d7d7;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #d7d7d7;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #d7d7d7;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #d7d7d7;
    }

    &.title {

        @extend .title;

    }

    &:focus {

        border: 1px solid $gray_1 !important;
        color: $gray_1;

    }
    &.inative{

        margin: 0px;
        height: auto;
        width: 100%;
        padding: 3px;

        &:disabled{

            background: none;
            border: none;
            box-shadow: none;
            padding: 0px;
            resize: none;
        }

    }

    &[disabled="disabled"] {
        opacity: 0.4;
        cursor: not-allowed;
    }

}

select {

        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
        padding: .5em;
        padding-right: 1.5em;
        background-color: #fff;

}

.select {

    float: left;
    width: 100%;
    height: 45px;
	// z-index: 0;
    position: relative;
    background-color: $blue;

	.selectize-dropdown {
		z-index: 9999999999 !important;
	}

	.selectize-control{
		z-index: 0 !important;
		.selectize-input{
			z-index: 0 !important;
			position: relative !important;
		}
	}

    &:not(.selectize-dropdown):after {
        content: '\f0d7';
        font-family: $fa;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 45px;
        text-align: center;
        line-height: $input-height;
        width: 40px;
        color: #fff;
        border-left: 1pt solid rgba(134, 176, 212, 0.41);
        background: $blue;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

    }

    select {

        background: transparent;
        // border-color: $gray_3;
        box-shadow: none;
        color: #fff;
        border-radius: none;
        font-family: $regular;
        z-index: 9999;
        position: relative;

        &:focus {

            color: #fff;

        }

    }
}

.date, .hour {

    position: relative;
    background: #fff;

    input {

        padding-left: 60px;
        z-index: 9;
        position: relative;
        background: none;
        text-transform: uppercase;
        border: 1px solid $gray_7 !important;

        &:focus {

            border: 1px solid #d7d7d7 !important;

        }

    }

    &:before {
        content: '\f073';
        font-family: 'fontAwesome';
        width: 50px;
        height: $input-height;
        position: absolute;
        left: 0px;
        top: 0px;
        border-right: 1pt solid #d7d7d7;
        line-height: $input-height;
        font-size: 16px;
        text-align: center;
        color: #676a6c;
        z-index: 1;
    }

}

.hour{

    &:before {
        z-index: 99;
        content: '\f017';
        font-family: 'fontAwesome';

    }

}

input[type="file"]
{
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    cursor: pointer;
    z-index: 999999999;
}


input[type="radio"],
input[type="checkbox"] {

    display: none;

}

.icheckbox_square-green,
.iradio_square-green {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        width: 22px;
        height: 22px;
        background: url(green.png) no-repeat;
        border: none;
        cursor: pointer;
}

.icheckbox_square-green {
        background-position: 0 0;
}
.icheckbox_square-green.hover {
        background-position: -24px 0;
}
.icheckbox_square-green.checked {
        background-position: -48px 0;
}
.icheckbox_square-green.disabled {
        background-position: -72px 0;
        cursor: default;
}
.icheckbox_square-green.checked.disabled {
        background-position: -96px 0;
}

.iradio_square-green {
        background-position: -120px 0;
}
.iradio_square-green.hover {
        background-position: -144px 0;
}
.iradio_square-green.checked {
        background-position: -168px 0;
}
.iradio_square-green.disabled {
        background-position: -192px 0;
        cursor: default;
}
.iradio_square-green.checked.disabled {
        background-position: -216px 0;
}

.radio {

    & + label {

        position: relative;
        cursor: pointer;
        padding: 0;
        font-family: $bold;
        font-size: 14px;
        color: $gray_1 !important;

    }

    & + label:before {

        content: '';
        background: #fff;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
        border: 1pt solid #d7d7d7;
        color: #949494;
        transition: .400s linear;
        box-shadow: inset 0 0 1px  1px #f4f4f4;
        border-radius: 100%;

    }

 /* &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }*/

    &:checked + label:before {
        background: #0d61a8;
        box-shadow: none !important;
    }

    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 10px;
        background: white;
        width: 3px;
        height: 3px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
    }

    &.radio-jof-approval:checked + label:after {
        left: 6px;
        top: 18px;
    }

}
.checkbox {

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-family: $bold;
        font-size: 14px;
        color: $gray_1;
    }

    & + label:before {

        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border: 1pt solid #d7d7d7;
        color: #949494;
        box-shadow: inset 0 0 1px  1px #f4f4f4;
        transition: .380s ease;
        background: #fff;

    }

 /* &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }*/

    &:checked + label:before {
        background: #0d61a8;
        box-shadow: none !important;
    }

    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 12px;
        background: white;
        width: 3px;
        height: 3px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
    }

}


.file-wrapper{

    position: relative;
    float: left;
    width: 100%;
    height: 47px;
    border: 1px solid #d7d7d7;
    background: #fff;
    margin-bottom: 20px;

    .upload-extensao-copy {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 460px;
    }

}

.form-group.has-error {
    .file-wrapper {
        border-color: red;
    }
}

.file-wrapper a
{
        color: #000;
        right: 0px;
        height: 50px;
        position: absolute;
        top: 0px;
        height: 100%;
        padding: 10px;
        opacity: 1;
        width: 100%;
}

.upload-extensao,
.upload-extensao-copy
{
    padding-left: 20px;
    line-height: 44px;
    text-align: left;
    float: left;
}

.upload-selecione:before
{
    content: '\f093';
    font-family: 'fontAwesome';
    position: absolute;
    top: -1px;
    right: -3px;
    height: 100%;
    width: 75px;
    color: #fff;
    font-size: 15px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;
    line-height: 44px;
    border-color: 1pt solid $label-color;
    background: $label-color;
    height: 47px;
}

// .upload-selecione:hover:before
// {
//     background: $navy;
// }

.action-group {

    a {

        width: 100%;
        float: left;
        margin-bottom: 5px;
    }

    color: $gray_1;
    font-size: 14px;

    .fa {

        color: $gray_1;

    }

}

form.ajax-form {
    .bt {
        transition: .2s linear;
    }

    &.sending {

        input[type="text"], input[type="email"], textarea {
            background: #dfdfdf;
        }

        #default {
            display: none;
        }

        .bt {
            background: #999999;
            // padding: 0px;

            #sending {
                padding: 0;
                display: block !important;
            }
        }
    }

    input, textarea {
        &.has-error {
            border-color: #F24848;
            color: #A5383D;
            box-shadow: none;
        }
    }

    &.response {
        input, textarea {
            opacity: 0.8;
        }

        #default {
            display: none;
        }

        .bt {
            background: #2ecc71;
            // padding: 0px;

            #response {
                padding: 0;
                display: block !important;
                // text-transform: uppercase;
            }
        }
    }
}

// General inline forms
form, .form {
    .nowarp {
        white-space: nowrap;

				b {
					margin-bottom: 4px;
					display: inline-block;
				}
    }
}


form, .form {
    position: relative;
    // Timepicker input
    .time-picker.hour {
        input.display-time {
            padding-left: 60px;

            position: relative;
            background: 0 0;
            text-transform: uppercase;
            height: 46px;
        }

        .dropdown {
            top: calc(2.2em + 10px);
            width: calc(10em - 1px);
            left: 1px;
        }
    }


    &.form-group, .form-group {
        padding-top: 10px;

        &.has-error div.select {
            margin-bottom: 0px;
        }

        .title-form {
            font-size: 25px;
            font-family: $semibold !important;
            padding-bottom: 0px;
            margin-bottom: 0px;
            @extend .full;
            color: $blue;
            border-bottom: none;

            input {
                color: $blue;
            }
        }

        // Error UI
        span.help-block {
            width: 100%;
            float: left;
            font-size: 12px;
            vertical-align: top;
            margin-top: 5px;
            word-wrap: break-word;
            line-height: 14px;
        }

        input,textarea, select {
            margin-bottom: 4px;

              &[disabled="disabled"] {
                opacity: 0.4;
                cursor: not-allowed;
              }
        }

        &.has-success {
            padding-bottom: 14px; // Compensates the span
        //     span.help-block {
        //         display: none;
        //     }
        }

        &.has-error {
            text-align: left;
            margin-bottom: 0px;

            input {
                border-color: #F24848;
                background-color: #D7A5A6;
                color: #A5383D;
                box-shadow: none;
            }

            textarea, select {
                box-shadow: none;
                border: 1px solid #F24848;
            }

            span.help-block {
                color: #F24848;
            }
        }
    }
}

.form-group .fa-trash-o {
  border: 1pt solid #eee;
  height: $input-height;
  width: 47px;
  text-align: center;
  line-height: 46px;
  font-style: normal;
  color: $gray_1;
  z-index: 0px;
}

.time-picker {
  li {
    &.active {
      background: $blue !important;
    }
  }
}

form.static-position {
    position: static;
}