.lastitems {

  display: inline-block;
  width: 33%;
  text-align: right;

}

// table.edit-bidding-table {
  // td.item.money-positions i {
  //   left: 40px;
  // }
// }

#user-foto {

  figure {

    background: url('../img/site/avatar.jpg') center center no-repeat #eee;
    height: 152px;
    position: relative;
    margin-bottom: 20px;

    label{

      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0;
      left: 0px;
      height: 28px;
      line-height: 28px;
      background: rgba(255,255,255,.67);
      text-align: center;

    }
  }

}

#menu-logged {

  color: #fff;

  li, i {

    color: #fff;
    position: relative;
    display: inline-block;

  }

  li {

    margin-right: 40px;

  }

  #notification {

    span {

      width: 12px;
      height: 12px;
      position: absolute;
      top: -4px;
      right: -7px;
      text-align: center;
      background: #dd2424;
      font-size: 9px;
      border-radius: 2px;
      font-family: $bold;

    }

  }

  #menu-user {

    color: #75bee9;
    margin-right: 0px;
    vertical-align: sub;

    a {

      color: #75bee9;

      i {

        color: #75bee9;
        margin: 0 5px;

      }

      &:hover {

        color: #fff;

        i {

          color: #fff;

        }

      }

    }

    &:hover {

      ul {

        padding-top: 27px;
        opacity: 1;
        visibility: visible;

      }

    }

    ul {

        position: absolute;
        width: 215px;
        right: 0px;
        text-align: left;
        z-index: 9999999;
        opacity: 0;
        transition: .400s ease;
        visibility: hidden;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.22);

        li {

          margin: 0px;
          width: 100%;
          background: #fff;

          a {

            padding: 15px 18px;
            background: #fff;
            float: left;
            width: 100%;
            min-height: $input-height;
            font-size: 15px;
            font-family: $bold;
            z-index: 9999999;
            display: inline-block;
            position: relative;
            color: $gray_1;

            &:hover {

              color: $label-color;
              background: #f7f7f7;
              color: $label-color;
              background: #f7f7f7;

              &:after {

                content: '';
                width: 4px;
                height: 100%;
                left: 0px;
                top: 0px;
                position: absolute;
                background: $bt-default;

              }
            }

            // &.active_ {
            //
            //   color: $label-color;
            //   background: #f7f7f7;
            //
            //   &:after {
            //
            //     content: '';
            //     width: 4px;
            //     height: 100%;
            //     left: 0px;
            //     top: 0px;
            //     position: absolute;
            //     background: $bt-default;
            //
            //   }
            //
            // }

          }

        }

      }

    }

  }

.vendor-process-opportunities {
  header {
    height: auto;
  }
}

