#consultants {
  header {
    #submenu-options {
      padding: 16px 0;

      .icon-consultants-rosters {
        font-size: 20px;
      }
    }
  }

  .table.table-4 tbody tr td {
    word-wrap: break-word;
  }

  .alert-error {
    padding: 20px;
    background-color: #f44336;
    color: white !important;

    strong {
      color: white !important;
    }
  }

  .link-clone {
    i {
      vertical-align: text-top !important;
      font-size: 19px;
    }
  }

  .closebtn {
    margin-left: 15px;
    color: white !important;
    font-weight: bold;
    float: right;
    font-size: 22px !important;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black !important;
  }

  .consultants-create-network {
    label {
      width: auto;
    }

    i {
      float: left;
      margin-right: 10px;
      font-size: 19px;
    }
  }

  .remove-actuation-area {
    .fa-trash {
      font-size: 22px;
      line-height: 46px;
    }
  }

  .consultants-show-edit {
    font-size: 13px;
    margin: 2px 15px 0 0;
    font-family: $semibold;
  }

  &.consultants-create {
    &.external {
      footer {
        &#footer-system {
          padding: 0 !important;
          margin: 0;
        }

        .copy {
          margin-top: 15px;
        }
      }

      header {
        padding: 20px 0;
        background: url("../img/site/licitacoes/header.jpg") center/cover no-repeat;

        #menu-logged {
          #menu-user {
            ul {
              padding-top: 29px;

              li {
                margin: -2px;
              }
            }
          }
        }
      }

      section {
        &.white {
          padding: 0 !important;
        }
      }
    }
  }

  .detalhes-fornecedor {
    #recomendacoes {
      li {
        padding: 50px 0 20px;
      }
    }
  }

  input[disabled="disabled"] {
    opacity: 0.4;
  }

  .intl-tel-input {
    width: 100%;
  }

  .selectize-nacionality {
    .selectize-input {
      display: flex;

      .item {
        margin: 10px 5px 10px 0;
        padding: 3px 10px !important;
        background: #3da0f4;
      }
    }
  }

  .form-group {
    padding: 0;
  }

  hr {
    margin: 25px 0;
    border-bottom: 1px solid #ebebeb;
  }

  .consultants-img {
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      height: 175px;
    }

    img {
      min-width: 170px;
      width: 170px;
      text-align: center;
    }

    .consultants-img-upload {
      position: absolute;
      bottom: 0;
      left: 50%;
      background: #c3cfda;
      width: 100%;
      padding: 5px 0;
      line-height: 8px;
      transform: translate(-50%, 0%);

      i {
        font-size: 12px;
        color: #fff;
        margin-right: 10px;
      }

      span {
        font-family: $heavy;
        font-size: 12px;
        color: #fff;
      }
    }
  }

  &.consultants-create {
    .fa-check-square-o {
      color: #27ae60;
    }

    .consultants-create-area {
      .link-clone {
        margin: 13px 0 0 30px;
        display: inline-block;
      }
    }

    .show-experiences {
      float: none;
      display: inline-block;
      text-align: left;

      .show-experiences-actions {
        margin-bottom: 10px;

        a {
          margin-right: 10px;
          font-size: 18px;
        }

        .fa-check-square-o,
        .fa-edit {
          vertical-align: bottom;
        }
      }

      .show-experiences-content {
        border-left: 5px solid $blue_1;
        padding: 30px;
        display: inline-block;

        span {
          margin: 0;
          color: $blue_1;
          font-family: $bold;
          font-size: 14px;
        }

        .show-experiences-title {
          font-size: 20px;
          font-family: $black;
          margin-bottom: 5px;
        }

        .show-experiences-subtitle {
          font-family: $semibold;
          font-size: 18px;
        }

        .fa-building {
          font-size: 30px;
          margin-left: 10px;
        }
      }
    }

    .enable-save-link {
      .save {
        display: inline-block;
      }

      .edit {
        display: none;
      }
    }

    .show-experiences-content {
      textarea[disabled="disabled"] {
        border: 0;
        outline: none;
        box-shadow: none;
        resize: none;
      }

      textarea {
        margin: 0 0 0 -5px !important;
        padding: 5px;
      }
    }
  }

  main {
    padding: 0 !important;
  }

  .new-shortlist-field {
    input {
      margin-bottom: 40px !important;
    }
  }

  b {
    color: #5f5f5f;
    font-family: $semibold;
    font-size: 14px;
  }

  input {
    margin-bottom: 0 !important;
  }

  label {
    margin: 0;
  }

  h3 {
    font-size: 20px;
    font-family: $black;
    color: #5f5f5f !important;
    padding-right: 50px;
  }

  p {
    color: #909090;
    font-family: $semibold;
    margin-bottom: 29px;
  }

  span {
    font-size: 14px;
    color: #909090;

    strong {
      font-family: $black;
      color: #5f5f5f;
      padding-right: 5px;
    }

    b {
      padding-left: 5px;
    }
  }

  .line-left {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -35px;
      top: 2px;
      height: 100%;
      border-left: 2px solid #ebebeb;
    }
  }

  .panel-body {
    border: 0;
  }

  .padding-box-shadow {
    padding-top: 50px;
  }

  .consultants-filter {
    background: #f7f7f7;
    box-shadow: 0 0 0 50px #f7f7f7;

    input[type="checkbox"],
    input[type="radio"] {
      display: block;
      border: none;
      box-shadow: none;
    }

    .consultants-filter-registers {
      color: #103c62;
      font-size: 14px;
      font-family: $black;
    }

    .consultants-filter-title {
      background-color: #c7ced6;
      font-family: $heavy;
      color: #20405c;
      margin: 20px 0;
      padding: 5px 20px;
    }

    .consultants-filter-items {
      overflow-y: scroll;
      height: 250px;
    }

    .consultants-filter-item {
      margin-bottom: 8px;
      width: 100% !important;
      display: block;
      float: left;
      clear: left;
      position: relative;
    }

    .consultants-filter-specialty {
      li {
        label {
          margin-bottom: 8px;
        }
      }
    }

    .consultants-filter-item {
      input[type="checkbox"] {
        width: auto;
        float: left;
        height: auto;
        padding: 0;
        margin: 2px 7px 0 0;
      }

      label {
        width: 90%;
        color: #838383;
        font-size: 13px;
        font-family: $semibold;
        padding: 0;
        text-overflow: inherit;
        overflow: inherit;
        white-space: normal;
      }
    }

    .consultants-filter-submit {
      button {
        height: 40px;
        padding: 0 30px !important;
      }
    }

    .filter-clear {
      line-height: 20px;
    }
  }

  .consultants-show {
    .shorlist-actions {
      position: relative;
      width: 220px;
      float: left;

      .search,
      .shortlist-remove {
        position: absolute;
        top: 0;
        font-size: 14px;
        font-family: $heavy;

        i {
          padding-right: 5px;
        }
      }

      .search {
        right: 0;
      }

      .shortlist-remove {
        left: 0;

        i {
          font-size: 16px;
        }
      }
    }

    h1 {
      border-bottom: 1pt solid #f0f0f0;
      padding-bottom: 40px;
    }

    hr {
      border-bottom: 1pt solid #ebebeb;
    }

    .consultants-show-item {
      .consultants-show-item-img {
        float: left;
        max-width: 120px;
      }

      .consultants-show-item-text {
        display: inline-block;
        color: #5f5f5f;
        margin-left: 25px;
        position: relative;
        min-height: 116px;

        .column4 {
          padding-right: 40px;
        }
      }
    }
  }

  .panel {
    .panel-body {
      .consultants-show-item-text {
        width: 85%;
      }
    }
  }

  .consultants-show-profile {
    .header {
      background-color: #F3F3F4;
    }

    .consultants-show-profile-img {
      width: 230px !important;

      img {
        width: 80%;
      }
    }

    p {
      margin-bottom: 20px;
    }
  }

  .consultants-show-profile-main,
  .consultants-show-profile-personal,
  .consultants-show-profile-specialty {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .consultants-show-profile-attachments,
  .consultants-show-profile-education,
  .consultants-show-profile-languages,
  .consultants-show-profile-references {
    padding-bottom: 40px;
  }

  .consultants-show-profile-experience {
    padding-right: 20px;
    padding-left: 20px;
  }

  .consultants-show-profile-networks {
    width: 80%;

    a {
      margin: 10px 17px;
      display: inline-block;
      transition: 500ms linear;
    }

    i {
      font-size: 26px;

      &:before {
        color: #14334e;
      }

      &:hover {
        &:before {
          color: $blue_4;
        }
      }
    }
  }

  .consultants-show-profile {
    span {
      font-size: 14px;
      padding: 2px 0;
      display: inline-block;
      line-height: 17px;
    }
  }

  .font-15 {
    font-size: 15px;
  }

  .consultants-show-profile-main {
    span {
      font-size: 16px;
    }

    .column4.right {
      span {
        font-size: 14px;
      }
    }
  }
}

.agency-image {
  max-height: 50px;
  width: 50px;
  max-width: 50px;
}