.list {

  &.list1 {

    i {

      margin-right: 8px;

    }

    b, i {

      color: $label-color;

    }

  }

  &.list3 {

    &.card {

      li {

        float: left;
        width: 100%;
        margin-top: 20px;

        h2 {

          color: $label-color;

        }

        .card-content {

          background: #fff;
          float: left;
          width: 100%;
          padding: 30px;
          border-left: 6px solid $label-color;

          i {

            color: $label-color;
            font-size: 20px;
            text-align: center;

          }

        }

      }

    }

      li {

        b {

          color: $label-color;

        }
        span {

          @extend .full;
          margin: 0px !important;
          padding: 0px !important;
          margin-bottom: 6px !important;

        }

        .actions {

          a {

            display: inline-block;
            vertical-align: top;
            color: $gray_1;
            font-size: 12px;
            margin: 0 15px;


            i {

              display: inline-block;
              font-size: 25px;
              // margin-bottom: 18px;

            }

            &:hover {

              color: $label-color;

            }

          }

        }

      }

  }

  &.list2 {

    li {

      border-top: 2px solid #eee;
      border-bottom: 2px solid #eee;
      margin-bottom: -2px;
      padding: 40px 0;
      @extend .full;

      &:hover{

        background: #f7f7f7;

      }

      span {

        float: left;
        width: 100%;
        margin-bottom: 10px;

      }

      .actions {

        a {

          display: inline-block;
          vertical-align: top;
          color: $gray_1;
          font-size: 12px;
          margin: 0 15px;

          i {

            display: inline-block;
            font-size: 25px;
            // margin-bottom: 18px;

          }

          &:hover {

            color: $label-color;

          }

        }

      }

    }

  }


  li {

    margin-bottom: 10px;
    float: left;
    width: 100%;

  }

}


.list-z-index {

  .multiselect__content-wrapper {
    z-index: 999;
  }

  .multiselect__element {
    margin-bottom: 5px;
 }

  li span {
    padding-left: 30px !important;
  }

  .multiselect__option--disabled{
     font-family: $bold;
     pointer-events:none;
     padding-left: 0px !important;
  }

}

.categorias-scroll {
  max-height: 200px;
  overflow-y: auto;
}

.access-data b {
  font-size: 14px;
}
