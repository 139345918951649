// #toastr-container {

    #toast-container.toast-top-full-width {
        top: 50px;
        opacity: 0.9;
        box-shadow: none;

        > div {
            width: 94%;
            box-shadow: none;
            padding: 18px 250px 18px 260px;
        }

        .toast {

            &.toast-success {
                background-position-x: 220px;
                background-color: #3BC1A8;

                border-bottom: 9px solid #24816F;
            }

            .toast-close-button {
                right: 0;
                top: 0;
                text-shadow: none;
                opacity: 1;
            }
        }
    }
// }

#toast-container {
  z-index: 9999999999999 !important;
}
