.lang {

  float: left;
  width: 100%;
  text-align: right;
  padding-right: 10px;
  padding: 37px 0 27px;
  b{
    font-weight: bold;
  }
  a {

    font-size: 16px;
    font-family: $medium;
    position: relative;

    &:first-child{
      
      margin-right: 5px;
      padding-right: 10px;

      &:before{
        content: '';
        position: absolute;
        right: -1px;
        height: 15px;
        top: 4px;
        width: 1px;
        background: #fff;
        border-right: 1px solid #fff;

      }

    }

  }

}
