.table-content {
	@extend .full;
	padding-bottom: 50px;
}

.table-vertical-middle {
	td {
		display: table-cell !important;
		float: none !important;
	}
}

.table {
	font-family: $regular;
	font-size: 14px;
	color: $gray_1;
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 30px;

	tbody {
		tr {
			transition: 0.400s linear;

			&:hover {
				background: #fff !important;
			}
		}

		&.no-hover {
			tr {
				&:hover {
					background: initial !important;
				}
			}
		}
	}

	&.every-two-striped {
		tbody {
			tr {
				background: #f0f0f0 !important;
			}

			tr {
				&:nth-child(even) {
					// border-bottom: 0px;
					// box-shadow: none;
				}

				&:nth-child(odd) {
					border-top: 0px;
					box-shadow: none;
				}

				&:nth-child(4n-1),
				&:nth-child(4n) {
   					background: #fafafa !important;

				}
			}
		}
	}

	&.odd {
		tbody {
			tr {
				background: #f0f0f0 !important;
			}

			tr:nth-child(odd) {
				background: #fafafa !important;
			}
		}
	}

	&.table-gray {
		tbody {
			tr {
				background: #f0f0f0 !important;

				&:hover {
					background: #f0f0f0 !important;
				}
			}
		}
	}

	.actions {
		a,
		button {
			margin-right: 20px;
			font-size: 22px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.table-4 {
		.fa {
			font-size: 17px;
			color: #686868;
		}

		thead {
			background: #fafafa;

			tr {
				th {
					color: $blue;
					font-size: 16px;
					padding: 20px;
				}
			}
		}

		tbody {
			tr {
				background: #f0f0f0;
				padding: 10px;
				border-top: 1pt solid #e3e3e3;
				border-bottom: 1pt solid #e3e3e3;
				box-shadow: none;

				td {
					padding: 20px;
					color: #949494;
					font-size: 15px;
					box-shadow: none;
				}

				&:nth-child(even) {
					background: #fafafa;
				}
			}
		}
	}

	&.table-5 {
		thead {
			background: $blue;

			tr {
				th {
					padding: 14px;
					font-size: 16px;
					font-family: $heavy;
				}
			}
		}

		tbody {
			tr {
				transition: 0.400s linear;
				background: #fafafa;
				border-left: 3pt solid transparent;

				&:nth-child(even) {
					background: #fafafa;
				}

				&:hover {
					transition: 0.2s linear;
					background: #ffffff;
					border-left: 3pt solid #848483;
				}

				td {
					padding: 20px;
					font-family: $semibold;

					input {
						margin-bottom: 0;
					}

					&.section {
						color: $label-color;
						font-size: 14px;
						font-family: $heavy;
						background: #e5e5e5;
					}
				}
			}
		}
	}

	&.min {
		.footable-header {
			td {
				font-size: 14px !important;
				font-weight: bold;
			}
		}

		thead {
			td {
				cursor: pointer;

				i.sorting {
					display: none;
				}

				&.sorted {
					background-color: #20405C;

					i.sorting {
						display: inline;
					}
				}
			}
		}

		td,
		th {
			padding: 14px !important;
			font-size: 14px !important;
		}
	}

	a {
		color: $gray_1;

		&:hover {
			color: $blue;
		}
	}

	&.table-1 {
		thead {
			th {
				padding: 14px;
			}
		}
	}

	thead {
		background: $blue_1;
		color: #fff;

		th {
			padding: 30px;

			span {
				&.fooicon {
					&:before {
						content: '\f0dd';
						font-family: $fa;
					}
				}
			}
		}
	}

	tbody {
		tr {
			box-shadow: inset 0 -1px 0 #e7e7e7;
			border-bottom: 1pt solid #ffffff;

			&:nth-child(even) {
				background: $gray_2;
				box-shadow: inset 0 1px 0 #fff !important;
				border-bottom: 1pt solid #e3e3e3;
			}

			td {
				padding: 20px;
				// border-bottom: 1px solid #dfdfdf;
			}
		}

		table {
			tbody {
				tr {
					box-shadow: none !important;
    			border: none !important;
				}
			}
		}
	}
}

.list-content {
	ul {
		li {
			margin-bottom: 14px;
			@extend p;
		}
	}

	i {
		color: $label-color;
		font-size: 23px;
		margin-right: 20px;
	}

	b {
		font-size: 14px;
		color: $label-color;
		font-family: $black;
	}
}

table {
	width: 100%;
	border: none;

	b {
		font-family: $heavy;
		color: #686868;
	}

	&.gray {
		color: #949494;
		font-family: $semibold;

		&.list {
			tbody {
				tr {
					border-bottom: 0 !important;
				}
			}

			&.simple {
				th {
					color: $blue;
					font-family: $heavy;
					padding: 20px;
					border-bottom: 1pt solid $gray_3;
				}

				tr {
					&:nth-child(odd) {
						background: #f0f0f0;
					}
				}

				.single {
					text-align: center;
					color: $gray_1;
					background: #fff;
					border-bottom: none;
					padding-left: 0;
					padding-right: 0;
				}
			}

			thead {
				color: $gray_1;
				background: none;
				color: $blue;
				font-size: 16px;
				font-family: $heavy;

				th {
					padding: 20px;
					background: #fafafa;
				}
			}

			tbody {
				tr {
					box-shadow: none;
					border-bottom: 1pt solid $gray_3;

					&:nth-child(odd) {
						td {
							background: #f0f0f0;
						}
					}

					td {
						padding: 20px;
						background: #fafafa;
					}
				}
			}
		}

		a {
			color: #949494;

			&:hover {
				color: $blue;
			}
		}

		thead {
			color: $blue;

			tr {
				th {
					border-bottom: 1pt solid #e3e3e3;
				}
			}
		}
	}
}

.no-footable {
	i {
		margin-bottom: 5px;
	}

	span {
		display: block;
	}
}

#permissions {
	span {
		font-size: 14px;
	}
}

.follow-up {
	b {
		font-size: 14px;
	}
}

.form-group {
	select {
		margin-bottom: 0 !important;
	}

	input {
		margin-bottom: 0 !important;
	}
}

.observations {
	height: 150px;
}

table {
	tr.clickable {
		cursor: pointer;
	}
}

.file-return {
	margin: 0;
}

#landing .table thead th {
	padding: 20px;
}

.table {
	&.min {
		&.table-fix-padding {

			thead {
				th {
					padding: 14px !important
				}
			}

			td {
				padding: 14px !important;
			}
		}
	}
}

.table.table-1.coa-table {
	thead {
		tr {
			th {
				padding-left: 10px;
			}
		}
	}
	tbody {
		tr.no-padding {
			border: 0;

			td {
				padding: 0px;

				input {
					border: 0;
					border-bottom: 1px solid #ccc;
				}
			}
		}
	}
}