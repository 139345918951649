.pagination-padding-fix {
  a {
    padding-top: 6px !important;
  }
}

#pagination {
  float: left;
  width: 100%;
  text-align: center;
  // padding-top: 40px;

  .pagination-button {

    vertical-align: top;

    // a {
    //   padding-top: 9px;
    // }
  }

  li {
    list-style: none;
    display: inline-block;
    margin-right: -5px;

    &:first-child {
      a {
        border-top-left-radius: $btn-border-radius;
        border-bottom-left-radius: $btn-border-radius;
      }
    }

    &:last-child {
      a {
        border-top-right-radius: $btn-border-radius;
        border-bottom-right-radius: $btn-border-radius;
      }
    }

    a {
      color: inherit;
      background: white;
      border: 1px solid #e7eaec;
      display: inline-block;
      padding: 6px 12px;
      font-size: 14px;
      color: $gray_1;

      &:hover {
        background: $gray;
      }

      &.active {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
      }

      &.active-page {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
      }
    }
  }
}

.pagination-correct {
  .active-page, .disabled {
    padding: 2px 12px !important;
  }
}
