
table {

  .date {

    position: relative;
    z-index: auto !important;

  }

}


.date {

  float: left;
  z-index: auto !important;
  // z-index: 999999999999 !important;
  // overflow: hidden;

  &:nth-child(1){

    &:after {

      display: none;

    }
  }

  &:nth-child(2){

    &:after {

        // content: "a";
        // position: absolute;
        // left: -14px;
        // height: 4px;
        // top: 50%;
        // margin-top: -24px;
        // font-family: $heavy;
        // color: $gray_5;
    }

  }

  input {

    padding-left: 33px;

  }

  .date-icon {

    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0px;
    left: 0px;

    &:before {
        z-index: 99;
        content: '\f073';
        font-family: $fa;
        font-style: normal;

        text-align: center;
        line-height: 46px;
        color: $gray_1;
        top: 0px;
        width: 50px;
        border-right: 1pt solid #d7d7d7;
        height: 46px;
        position: absolute;

    }

  }

}

.date-adjust-margin {
  input, select {
    margin-bottom: 0px !important;
  }
}

// Vue Js Datepicker
.vdp-datepicker {
  .vdp-datepicker__clear-button {
    color: #bbb;
    position: absolute;
    top: 13px;
    right: 16px;
    z-index: 1;
  }

  .vdp-datepicker__calendar {
    width: 100%;
    min-width: 300px;
    z-index: 99999;
  }
}
