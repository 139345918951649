.biddings {
  
  .fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  .fade.in {
    opacity: 1;
  }
  .collapse {
    display: none;
  }
  .collapse.in {
    display: block;
  }
  tr.collapse.in {
    display: table-row;
  }
  tbody.collapse.in {
    display: table-row-group;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
       transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    -o-transition-duration: 0.35s;
       transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
       transition-timing-function: ease;
  }
  .panel {
    background-color: #ffffff;
  }
  .panel-body {
    padding: 15px;
  }
  .panel-heading {
    // padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit;
  }
  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
  }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit;
  }
  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #dddddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel > .list-group,
  .panel > .panel-collapse > .list-group {
    margin-bottom: 0;
  }
  .panel > .list-group .list-group-item,
  .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }
  .panel > .list-group:first-child .list-group-item:first-child,
  .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .panel > .list-group:last-child .list-group-item:last-child,
  .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .panel-heading + .list-group .list-group-item:first-child {
    border-top-width: 0;
  }
  .list-group + .panel-footer {
    border-top-width: 0;
  }
  .panel > .table,
  .panel > .table-responsive > .table,
  .panel > .panel-collapse > .table {
    margin-bottom: 0;
  }
  .panel > .table caption,
  .panel > .table-responsive > .table caption,
  .panel > .panel-collapse > .table caption {
    padding-left: 15px;
    padding-right: 15px;
  }
  .panel > .table:first-child,
  .panel > .table-responsive:first-child > .table:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .panel > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
  .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }
  .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
  .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }
  .panel > .table:last-child,
  .panel > .table-responsive:last-child > .table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
  .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
  }
  .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
  .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
  }
  .panel > .panel-body + .table,
  .panel > .panel-body + .table-responsive,
  .panel > .table + .panel-body,
  .panel > .table-responsive + .panel-body {
    border-top: 1px solid #dddddd;
  }
  .panel > .table > tbody:first-child > tr:first-child th,
  .panel > .table > tbody:first-child > tr:first-child td {
    border-top: 0;
  }
  .panel > .table-bordered,
  .panel > .table-responsive > .table-bordered {
    border: 0;
  }
  .panel > .table-bordered > thead > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
  .panel > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-bordered > thead > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
  .panel > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-bordered > tfoot > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .panel > .table-bordered > thead > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
  .panel > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-bordered > thead > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
  .panel > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-bordered > tfoot > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .panel > .table-bordered > thead > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
  .panel > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-bordered > thead > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
  .panel > .table-bordered > tbody > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0;
  }
  .panel > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-bordered > tfoot > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
  .panel > .table-responsive {
    border: 0;
    margin-bottom: 0;
  }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
  }
  .panel-group .panel + .panel {
    margin-top: 5px;
  }
  .panel-group .panel-heading {
    border-bottom: 0;
  }
  .panel-group .panel-heading + .panel-collapse > .panel-body,
  .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: 1px solid #dddddd;
  }
  .panel-group .panel-footer {
    border-top: 0;
  }
  .panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #dddddd;
  }
  .panel-default {
    border-color: #dddddd;
  }
  .panel-default > .panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #dddddd;
  }
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #dddddd;
  }
  .panel-default > .panel-heading .badge {
    color: #f5f5f5;
    background-color: #333333;
  }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #dddddd;
  }
  .panel-primary {
    border-color: #337ab7;
  }
  .panel-primary > .panel-heading {
    color: #ffffff;
    background-color: #337ab7;
    border-color: #337ab7;
  }
  .panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #337ab7;
  }
  .panel-primary > .panel-heading .badge {
    color: #337ab7;
    background-color: #ffffff;
  }
  .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #337ab7;
  }
  .panel-success {
    border-color: #d6e9c6;
  }
  .panel-success > .panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  .panel-success > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #d6e9c6;
  }
  .panel-success > .panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d;
  }
  .panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #d6e9c6;
  }
  .panel-info > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #bce8f1;
  }
  .panel-info > .panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
  }
  .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #bce8f1;
  }
  .panel-warning {
    border-color: #faebcc;
  }
  .panel-warning > .panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }
  .panel-warning > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #faebcc;
  }
  .panel-warning > .panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b;
  }
  .panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #faebcc;
  }
  .panel-danger {
    border-color: #ebccd1;
  }
  .panel-danger > .panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .panel-danger > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ebccd1;
  }
  .panel-danger > .panel-heading .badge {
    color: #f2dede;
    background-color: #a94442;
  }
  .panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ebccd1;
  }
  .clearfix:before,
  .clearfix:after,
  .panel-body:before,
  .panel-body:after {
    content: " ";
    display: table;
  }
  .clearfix:after,
  .panel-body:after {
    clear: both;
  }
  .center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .pull-right {
    float: right !important;
  }
  .pull-left {
    float: left !important;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .invisible {
    visibility: hidden;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hidden {
    display: none !important;
  }
  .affix {
    position: fixed;
  }


  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }

  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    float: left;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-11 {
    width: 91.66666667%;
  }

  .col-xs-10 {
    width: 83.33333333%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-8 {
    width: 66.66666667%;
  }

  .col-xs-7 {
    width: 58.33333333%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-5 {
    width: 41.66666667%;
  }

  .col-xs-4 {
    width: 33.33333333%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-2 {
    width: 16.66666667%;
  }

  .col-xs-1 {
    width: 8.33333333%;
  }

  .col-xs-pull-12 {
    right: 100%;
  }

  .col-xs-pull-11 {
    right: 91.66666667%;
  }

  .col-xs-pull-10 {
    right: 83.33333333%;
  }

  .col-xs-pull-9 {
    right: 75%;
  }

  .col-xs-pull-8 {
    right: 66.66666667%;
  }

  .col-xs-pull-7 {
    right: 58.33333333%;
  }

  .col-xs-pull-6 {
    right: 50%;
  }

  .col-xs-pull-5 {
    right: 41.66666667%;
  }

  .col-xs-pull-4 {
    right: 33.33333333%;
  }

  .col-xs-pull-3 {
    right: 25%;
  }

  .col-xs-pull-2 {
    right: 16.66666667%;
  }

  .col-xs-pull-1 {
    right: 8.33333333%;
  }

  .col-xs-pull-0 {
    right: auto;
  }

  .col-xs-push-12 {
    left: 100%;
  }

  .col-xs-push-11 {
    left: 91.66666667%;
  }

  .col-xs-push-10 {
    left: 83.33333333%;
  }

  .col-xs-push-9 {
    left: 75%;
  }

  .col-xs-push-8 {
    left: 66.66666667%;
  }

  .col-xs-push-7 {
    left: 58.33333333%;
  }

  .col-xs-push-6 {
    left: 50%;
  }

  .col-xs-push-5 {
    left: 41.66666667%;
  }

  .col-xs-push-4 {
    left: 33.33333333%;
  }

  .col-xs-push-3 {
    left: 25%;
  }

  .col-xs-push-2 {
    left: 16.66666667%;
  }

  .col-xs-push-1 {
    left: 8.33333333%;
  }

  .col-xs-push-0 {
    left: auto;
  }

  .col-xs-offset-12 {
    margin-left: 100%;
  }

  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xs-offset-9 {
    margin-left: 75%;
  }

  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xs-offset-6 {
    margin-left: 50%;
  }

  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xs-offset-3 {
    margin-left: 25%;
  }

  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xs-offset-0 {
    margin-left: 0;
  }
  @media (min-width: 768px) {
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
      float: left;
    }

    .col-sm-12 {
      width: 100%;
    }

    .col-sm-11 {
      width: 91.66666667%;
    }

    .col-sm-10 {
      width: 83.33333333%;
    }

    .col-sm-9 {
      width: 75%;
    }

    .col-sm-8 {
      width: 66.66666667%;
    }

    .col-sm-7 {
      width: 58.33333333%;
    }

    .col-sm-6 {
      width: 50%;
    }

    .col-sm-5 {
      width: 41.66666667%;
    }

    .col-sm-4 {
      width: 33.33333333%;
    }

    .col-sm-3 {
      width: 25%;
    }

    .col-sm-2 {
      width: 16.66666667%;
    }

    .col-sm-1 {
      width: 8.33333333%;
    }

    .col-sm-pull-12 {
      right: 100%;
    }

    .col-sm-pull-11 {
      right: 91.66666667%;
    }

    .col-sm-pull-10 {
      right: 83.33333333%;
    }

    .col-sm-pull-9 {
      right: 75%;
    }

    .col-sm-pull-8 {
      right: 66.66666667%;
    }

    .col-sm-pull-7 {
      right: 58.33333333%;
    }

    .col-sm-pull-6 {
      right: 50%;
    }

    .col-sm-pull-5 {
      right: 41.66666667%;
    }

    .col-sm-pull-4 {
      right: 33.33333333%;
    }

    .col-sm-pull-3 {
      right: 25%;
    }

    .col-sm-pull-2 {
      right: 16.66666667%;
    }

    .col-sm-pull-1 {
      right: 8.33333333%;
    }

    .col-sm-pull-0 {
      right: auto;
    }

    .col-sm-push-12 {
      left: 100%;
    }

    .col-sm-push-11 {
      left: 91.66666667%;
    }

    .col-sm-push-10 {
      left: 83.33333333%;
    }

    .col-sm-push-9 {
      left: 75%;
    }

    .col-sm-push-8 {
      left: 66.66666667%;
    }

    .col-sm-push-7 {
      left: 58.33333333%;
    }

    .col-sm-push-6 {
      left: 50%;
    }

    .col-sm-push-5 {
      left: 41.66666667%;
    }

    .col-sm-push-4 {
      left: 33.33333333%;
    }

    .col-sm-push-3 {
      left: 25%;
    }

    .col-sm-push-2 {
      left: 16.66666667%;
    }

    .col-sm-push-1 {
      left: 8.33333333%;
    }

    .col-sm-push-0 {
      left: auto;
    }

    .col-sm-offset-12 {
      margin-left: 100%;
    }

    .col-sm-offset-11 {
      margin-left: 91.66666667%;
    }

    .col-sm-offset-10 {
      margin-left: 83.33333333%;
    }

    .col-sm-offset-9 {
      margin-left: 75%;
    }

    .col-sm-offset-8 {
      margin-left: 66.66666667%;
    }

    .col-sm-offset-7 {
      margin-left: 58.33333333%;
    }

    .col-sm-offset-6 {
      margin-left: 50%;
    }

    .col-sm-offset-5 {
      margin-left: 41.66666667%;
    }

    .col-sm-offset-4 {
      margin-left: 33.33333333%;
    }

    .col-sm-offset-3 {
      margin-left: 25%;
    }

    .col-sm-offset-2 {
      margin-left: 16.66666667%;
    }

    .col-sm-offset-1 {
      margin-left: 8.33333333%;
    }

    .col-sm-offset-0 {
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
      float: left;
    }

    .col-md-12 {
      width: 100%;
    }

    .col-md-11 {
      width: 91.66666667%;
    }

    .col-md-10 {
      width: 83.33333333%;
    }

    .col-md-9 {
      width: 75%;
    }

    .col-md-8 {
      width: 66.66666667%;
    }

    .col-md-7 {
      width: 58.33333333%;
    }

    .col-md-6 {
      width: 50%;
    }

    .col-md-5 {
      width: 41.66666667%;
    }

    .col-md-4 {
      width: 33.33333333%;
    }

    .col-md-3 {
      width: 25%;
    }

    .col-md-2 {
      width: 16.66666667%;
    }

    .col-md-1 {
      width: 8.33333333%;
    }

    .col-md-pull-12 {
      right: 100%;
    }

    .col-md-pull-11 {
      right: 91.66666667%;
    }

    .col-md-pull-10 {
      right: 83.33333333%;
    }

    .col-md-pull-9 {
      right: 75%;
    }

    .col-md-pull-8 {
      right: 66.66666667%;
    }

    .col-md-pull-7 {
      right: 58.33333333%;
    }

    .col-md-pull-6 {
      right: 50%;
    }

    .col-md-pull-5 {
      right: 41.66666667%;
    }

    .col-md-pull-4 {
      right: 33.33333333%;
    }

    .col-md-pull-3 {
      right: 25%;
    }

    .col-md-pull-2 {
      right: 16.66666667%;
    }

    .col-md-pull-1 {
      right: 8.33333333%;
    }

    .col-md-pull-0 {
      right: auto;
    }

    .col-md-push-12 {
      left: 100%;
    }

    .col-md-push-11 {
      left: 91.66666667%;
    }

    .col-md-push-10 {
      left: 83.33333333%;
    }

    .col-md-push-9 {
      left: 75%;
    }

    .col-md-push-8 {
      left: 66.66666667%;
    }

    .col-md-push-7 {
      left: 58.33333333%;
    }

    .col-md-push-6 {
      left: 50%;
    }

    .col-md-push-5 {
      left: 41.66666667%;
    }

    .col-md-push-4 {
      left: 33.33333333%;
    }

    .col-md-push-3 {
      left: 25%;
    }

    .col-md-push-2 {
      left: 16.66666667%;
    }

    .col-md-push-1 {
      left: 8.33333333%;
    }

    .col-md-push-0 {
      left: auto;
    }

    .col-md-offset-12 {
      margin-left: 100%;
    }

    .col-md-offset-11 {
      margin-left: 91.66666667%;
    }

    .col-md-offset-10 {
      margin-left: 83.33333333%;
    }

    .col-md-offset-9 {
      margin-left: 75%;
    }

    .col-md-offset-8 {
      margin-left: 66.66666667%;
    }

    .col-md-offset-7 {
      margin-left: 58.33333333%;
    }

    .col-md-offset-6 {
      margin-left: 50%;
    }

    .col-md-offset-5 {
      margin-left: 41.66666667%;
    }

    .col-md-offset-4 {
      margin-left: 33.33333333%;
    }

    .col-md-offset-3 {
      margin-left: 25%;
    }

    .col-md-offset-2 {
      margin-left: 16.66666667%;
    }

    .col-md-offset-1 {
      margin-left: 8.33333333%;
    }

    .col-md-offset-0 {
      margin-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
      float: left;
    }

    .col-lg-12 {
      width: 100%;
    }

    .col-lg-11 {
      width: 91.66666667%;
    }

    .col-lg-10 {
      width: 83.33333333%;
    }

    .col-lg-9 {
      width: 75%;
    }

    .col-lg-8 {
      width: 66.66666667%;
    }

    .col-lg-7 {
      width: 58.33333333%;
    }

    .col-lg-6 {
      width: 50%;
    }

    .col-lg-5 {
      width: 41.66666667%;
    }

    .col-lg-4 {
      width: 33.33333333%;
    }

    .col-lg-3 {
      width: 25%;
    }

    .col-lg-2 {
      width: 16.66666667%;
    }

    .col-lg-1 {
      width: 8.33333333%;
    }

    .col-lg-pull-12 {
      right: 100%;
    }

    .col-lg-pull-11 {
      right: 91.66666667%;
    }

    .col-lg-pull-10 {
      right: 83.33333333%;
    }

    .col-lg-pull-9 {
      right: 75%;
    }

    .col-lg-pull-8 {
      right: 66.66666667%;
    }

    .col-lg-pull-7 {
      right: 58.33333333%;
    }

    .col-lg-pull-6 {
      right: 50%;
    }

    .col-lg-pull-5 {
      right: 41.66666667%;
    }

    .col-lg-pull-4 {
      right: 33.33333333%;
    }

    .col-lg-pull-3 {
      right: 25%;
    }

    .col-lg-pull-2 {
      right: 16.66666667%;
    }

    .col-lg-pull-1 {
      right: 8.33333333%;
    }

    .col-lg-pull-0 {
      right: auto;
    }

    .col-lg-push-12 {
      left: 100%;
    }

    .col-lg-push-11 {
      left: 91.66666667%;
    }

    .col-lg-push-10 {
      left: 83.33333333%;
    }

    .col-lg-push-9 {
      left: 75%;
    }

    .col-lg-push-8 {
      left: 66.66666667%;
    }

    .col-lg-push-7 {
      left: 58.33333333%;
    }

    .col-lg-push-6 {
      left: 50%;
    }

    .col-lg-push-5 {
      left: 41.66666667%;
    }

    .col-lg-push-4 {
      left: 33.33333333%;
    }

    .col-lg-push-3 {
      left: 25%;
    }

    .col-lg-push-2 {
      left: 16.66666667%;
    }

    .col-lg-push-1 {
      left: 8.33333333%;
    }

    .col-lg-push-0 {
      left: auto;
    }

    .col-lg-offset-12 {
      margin-left: 100%;
    }

    .col-lg-offset-11 {
      margin-left: 91.66666667%;
    }

    .col-lg-offset-10 {
      margin-left: 83.33333333%;
    }

    .col-lg-offset-9 {
      margin-left: 75%;
    }

    .col-lg-offset-8 {
      margin-left: 66.66666667%;
    }

    .col-lg-offset-7 {
      margin-left: 58.33333333%;
    }

    .col-lg-offset-6 {
      margin-left: 50%;
    }

    .col-lg-offset-5 {
      margin-left: 41.66666667%;
    }

    .col-lg-offset-4 {
      margin-left: 33.33333333%;
    }

    .col-lg-offset-3 {
      margin-left: 25%;
    }

    .col-lg-offset-2 {
      margin-left: 16.66666667%;
    }

    .col-lg-offset-1 {
      margin-left: 8.33333333%;
    }

    .col-lg-offset-0 {
      margin-left: 0;
    }
  }

  .fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }

  .fade.in {
    opacity: 1;
  }

  .collapse {
    display: none;
  }

  .collapse.in {
    display: block;
  }

  tr.collapse.in {
    display: table-row;
  }

  tbody.collapse.in {
    display: table-row-group;
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    -o-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .dropdown,
  .dropup {
    position: relative;
  }

  .dropdown-toggle:focus {
    outline: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .dropdown-menu.pull-right {
    right: 0;
    left: auto;
  }

  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }

  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
  }

  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:focus,
  .dropdown-menu > .active > a:hover {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7;
  }

  .dropdown-menu > .disabled > a,
  .dropdown-menu > .disabled > a:focus,
  .dropdown-menu > .disabled > a:hover {
    color: #777777;
  }

  .dropdown-menu > .disabled > a:focus,
  .dropdown-menu > .disabled > a:hover {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    cursor: not-allowed;
  }

  .open > .dropdown-menu {
    display: block;
  }

  .open > a {
    outline: 0;
  }

  .dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .dropdown-menu-left {
    left: 0;
    right: auto;
  }

  .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777777;
    white-space: nowrap;
  }

  .dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
  }

  .pull-right > .dropdown-menu {
    right: 0;
    left: auto;
  }

  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }

  .dropup .dropdown-menu,
  .navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
  @media (min-width: 768px) {
    .navbar-right .dropdown-menu {
      left: auto;
      right: 0;
    }

    .navbar-right .dropdown-menu-left {
      left: 0;
      right: auto;
    }
  }

  .clearfix:after,
  .clearfix:before,
  .container-fluid:after,
  .container-fluid:before,
  .container:after,
  .container:before,
  .row:after,
  .row:before {
    content: " ";
    display: table;
  }

  .clearfix:after,
  .container-fluid:after,
  .container:after,
  .row:after {
    clear: both;
  }

  .center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .pull-right {
    float: right !important;
  }

  .pull-left {
    float: left !important;
  }

  .hide {
    display: none !important;
  }

  .show {
    display: block !important;
  }

  .invisible {
    visibility: hidden;
  }

  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .hidden {
    display: none !important;
  }

  .affix {
    position: fixed;
  }
  // Styles 
  //-----------------------------------------------------------------------------
  // .panel {
  //   height: auto;
  //   width: 100%;
  // }

  .panel-heading {
    width: 100%;
    float: left;
    background: #f7f7f7;

    .row {
      padding: 10px 20px;
      width: 100% !important;
      display: block;
      float: left;
      clear: left;
      position: relative;

      div {
        font-size: 14px;
        font-family: $regular;
        color: $gray_1;
        line-height: 23px;
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin-right: -5px;

        i {
          color: $gray_1;
          font-size: 24px;
          padding-right: 10px;
        }
      }
    }
  }

  .panel-body {
    display: inline-block;
    width: 100%;
    padding: 15px;
    border: 1px solid #f7f7f7;
    // border: 1px solid #f7f7f7;
    .row {
      // padding: 20px 0;
    }

    small {
      line-height: 26px;
      margin-bottom: 10px;
      display: inline-block;
      font-size: 13px !important;
    }

    label {
      padding-right: 10px;
      display: block;
      margin-top: 10px;
      padding: 0;
    }
  }

  .lead {
    color: #686868;
    line-height: 23px;
    font-family: $medium;
    font-size: 20px;
    margin-bottom: 20px;

    i {
      font-size: 23px;
    }
  }

  .accordion-heading {
    &.panel-heading {
      background: $blue;
      padding: 10px;
      // border-radius: 5px;
      a {
        color: white;
        font-family: $semibold;
        font-size: 14px;
      }
    }
  }

  .accordion-group.panel {
    margin-top: 0;
  }

  .accordion-inner {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      width: 100%;
    }
  }

  .tab-container {
    display: none;
    padding: 0px;
    margin-top: -3px;
    margin-bottom: 50px;
    
    &.tab-active {
      display: block;
    }
  }

  .tabs {
    display: inline-block;
    width: 100%;
    margin-bottom: -5px;

    li {
      width: 50%;
      float: left;

      .tab-link {
        background: #fff;
        color: #9d9d9d;
        border: 1px solid #e7eaec;
        box-shadow: inset 0 0 3px #f7f7f7 !important;
        position: relative;
        font-size: 15px;
        font-family: $heavy;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 15px 0;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;

        &.tab-selected {
          background: #f7f7f7;
          color: #0e62a8 !important;
        }
      }
    }
  }

  .table thead th {
    padding: 10px 20px;
  }
  
  .panel {
    margin-top: 5px;
    display: table;
    width: 100%;
  }
}

.bidding-link a {
  color: #0d61a8;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 10px;
  
  &:hover {
    color: $blue_1;
  }
}