.selectize-control {
    margin-bottom: 20px;

    .selectize-input {
      padding-top: 5px !important;
      cursor: pointer;
        input[type="text"] {
            height: 28px;
            top: 4px;
        }

        .item {
            width: initial !important;
        }
    }

    &.async::before {
        -moz-transition: opacity 0.2s;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
        content: ' ';
        z-index: 2;
        position: absolute;
        display: block;
        top: 12px;
        right: 34px;
        width: 16px;
        height: 16px;
        background: url(/img/manager/spinner.gif);
        background-size: 16px 16px;
        opacity: 0;
    }

    &.async.loading::before {
        opacity: 0.4;
    }
}

select {

    margin-bottom: 0px;
    font-family: "LatoWeb";
    color: #fff !important;
    line-height: 1;
    font-weight: bold;

    &::after {
        display: none;
    }

    background: transparent;
    margin-bottom: 0px;
    border-color: #0d61a8;
    box-shadow: none;
    color: #fff;
    border-radius: none;
    font-family: "LatoWeb";
    z-index: 8;
    position: relative;
    margin-bottom: 0;
    border: none;
    height: 46px;
}

div.select, .select {
    margin-bottom: 20px;

    .selectize-control {
        background: transparent;
        margin-bottom: 0px;
        border-color: #0d61a8;
        box-shadow: none;
        color: #fff;
        border-radius: none;
        font-family: "LatoWeb";
        z-index: 19 !important;
        position: relative;
        margin-bottom: 0;
        border: none;
        height: 46px;

        .selectize-input,
        .selectize-input.input-active {
            border: none;
            background: transparent;
            font-weight: 800;
            z-index: 9 !important;

            &.focus {
                box-shadow: none;
            }

            input {
                margin-bottom: 0px;
                font-family: "LatoWeb";
                color: #fff !important;
                line-height: 1;
                font-weight: bold;
                // width: 100% !important;
                // position: absolute !important;
                // height: 100%;
                // top: 0;

                &::after {
                    display: none;
                }
            }

            &::after {
                display: none;
            }

            .item {
                cursor: pointer;
                margin: 13px 6px 6px 0;
                padding: 0;
                background: transparent;
                color: #f7f7f7;
                border: none;
            }
        }
    }
}
