.simple-alert {
    margin-bottom: 30px;

    width: 100%;
    padding: 15px 20px;
    border: 1px solid;
    border-radius: 3px;
    font-size: 16px;
    line-height: 20px;

    i.simple-alert-icon {
        font-size: 18px;
    }

    &.alert-warning {
        border-color: #eceba6;
        background: #ffffd4;
        color: #d2a90a;

        b, i {
            color: #d2a90a;
        }
    }

    &.alert-danger {
        border-color: #f5c8c8;
        background: #ffd4d4;
        color: #b16666;

        b, i {
            color: #b16666;
        }
    }

    &.alert-success {
        border-color: #b8f3b7;
        background: #e0fbe4;
        color: #3d8e57;

        b, i {
            color: #3d8e57;
        }
    }
}