@media only screen and (max-width: 767px) {
	@import "mobile/celular";
}
@media only screen and (max-width: 1160px) and (min-width: 960px) {
	#banner {
		.logo {
			img {
				width: 150px;
			}
		}

		.landing-menu {
			float: left !important;
			text-align: left !important;

			.menu-desktop {
				float: left !important;
			}
		}
	}
}
@media only screen and (max-width: 1160px) {
	#landing {
		#services {
			.services {
				min-height: 523px;
			}
		}

		.landing-menu {
			a {
				width: 100%;
				text-align: left;
				margin: 0;
			}
		}
	}
}
@media only screen and (max-width: 960px) {
	.show-tablet {
		display: block !important;
	}

	.hidden-tablet {
		display: none !important;
	}

	.vendor-process-opportunities {
		.lastitems {
			.bt {
				width: 172px;
			}
		}

		.licitacoes-process {
			h1 {
				margin-bottom: 30px !important;
			}
		}

		.gray {
			.full {
				padding: 20px 0;

				h1 {
					margin-bottom: 0;
				}
			}
		}

		.licitacao-detail {
			.list-content {
				.fa {
					margin-right: 10px;
				}

				.column6 {
					width: 48% !important;
					margin-top: 20px;
					float: left;
					display: inline-block;
				}
			}
		}
	}
}

@media only screen and (min-width: 1400px) {
	#services {
		.services {
			min-height: 409px !important;
		}
	}
}