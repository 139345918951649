/*
----------------------------------------------
|		|		| EXTRAS |		|		|
----------------------------------------------
*/
.left {
	float: left;
}

.right {
	float: right !important;
}

.both {
	clear: both;
}

.error-color {
	color: #f24949 !important;

	&:hover {
		color: $blue;
	}
}

.opacity-none {
	opacity: 0;
}

.opacity-one {
	opacity: 1 !important;
}

.text-bottom {
	vertical-align: text-bottom !important;
}

.align-middle-baseline {
	vertical-align: -webkit-baseline-middle !important;
}

.no-float {
	float: none;
}

.align-baseline {
	vertical-align: baseline !important;
}

.center {
	margin: 0 auto !important;
	float: none !important;
}

.width-auto {
	width: auto;
}

.w-100 {
	width: 100%;
}

.margin-top-20 {
	margin-top: 20px !important;
}

.margin-top-30 {
	margin-top: 20px !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.middle {
	position: absolute;
	top: 50%;
	left: 50%;
	-moz-transform: translate(-50%);
	-ms-transform: translate(-50%);
	-o-transform: translate(-50%);
	transform: translate(-50%);
}

.half {
	width: 50%;
	display: inline-block;
	vertical-align: top;
	margin-right: -3px;
}

.adjust-menu div {
	display: inline-block;
}

.inline-block {
	display: inline-block !important;
}

.inline {
	float: none;
	display: inline !important;
}

.hidden {
	overflow: hidden;
}

.none {
	display: none !important;
}

.alpha {
	margin-left: 0 !important;
}

.border {
	border: 1pt solid #d1d7d8;
}

.no-border {
	border: none !important;
}

.no-border-left {
	border-left: 0 !important;
}

.no-border-top {
	border-top: 0 !important;
}

.no-border-bottom {
	border-bottom: 0 !important;
}

.no-border-right {
	border-right: 0 !important;
}

.omega {
	margin-right: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.no-margin-right {
	margin-right: 0 !important;
}

.margin-left {
	margin-left: 10px !important;
}

.margin-right {
	margin-right: 10px !important;
}

.margin-top {
	margin-top: 40px !important;
}

.margin-bottom {
	margin-bottom: 40px !important;
}

.help-block.margin-bottom {
	margin-bottom: 21px !important;
}

.padding {
	padding: 40px 0;
}

.padding-10-20 {
	padding: 10px 20px;
}

.padding-bottom-20 {
	padding-bottom: 20px;
}

.padding-left {
	padding-left: 10px !important;
}

.padding-left-26 {
	padding-left: 26px;
}

.padding-right {
	padding-right: 10px !important;
}

.padding-top {
	padding-top: 10px !important;
}

.content-padding-lateral {
	padding-left: 40px;
	padding-right: 40px;
}

.padding-bottom-50 {
	padding-bottom: 50px;
}

.padding-bottom {
	padding-bottom: 10px;
}

.no-margin-left {
	margin-left: 0 !important;
}

.no-margin-top {
	margin-top: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-background {
	background: none !important;
}

.no-margin-bottom {
	margin-bottom: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.line {
	outline: 1pt solid black;
}

.full {
	width: 100% !important;
	display: block;
	float: left;
	clear: left;
	position: relative;
}

.pos-relative {
	position: relative !important;
}

.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right;
}

.align-top {
	display: inline-block;
	vertical-align: top;
	float: none;
}

.align-bottom {
	display: inline-block;
	vertical-align: bottom;
	float: none;
}

.align {
	display: inline-block;
	float: none;
}

.align-middle {
	display: inline-block;
	vertical-align: middle;
	float: none;
	margin-right: -5px;
}

b.clean {
	float: left;
	width: 100%;
	margin-bottom: 10px;
}

.no-color {
	color: inherit !important;
}

.margin-20 {
	margin: 20px 0;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}
.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-5 {
	margin-bottom: 5px !important;;
}
.padding-top-5 {
	padding-top: 5px !important;
}

.margin-right-30 {
	margin-right: 30px;
}

.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.form-group-line-height {
	line-height: 45px;

	.checkbox:checked + label:after {
		top: 23px;
	}
}

.list2 {
	li {
		margin-bottom: 40px !important;
	}
}

.list3 {
	span {
		margin: 0px;
		padding: 0px;
	}
}

.error-edit-title {
	.help-block {
		margin-left: 134px;
	}
}

.adjust-label-after {
  .checkbox:checked + label:after {
    top: 10px;
  }

	.radio:checked + label:after {
		top: 10px;
	}
}

.table-no-last-arrow {
	.footable-last-visible {
		span {
			display: none;
		}
	}
}

.help-block-with-margin {
	.help-block {
		margin-bottom: 20px !important;
	}
}

.d-flex {
	display: flex !important;
}

.align-items-center {
	align-items: center !important;
}

.justify-content-between {
	justify-content: space-between;
}

.h-100 {
	height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}
