.h1,
h1 {
  font-size: 40px;
  margin-bottom: 60px;
  font-family: $regular;
  font-weight: 100;
}

.h2,
h2 {
  font-size: 30px;
  margin-bottom: 60px;
  font-family: $regular;
  font-weight: normal;
}

.h3,
h3 {
  font-size: 30px;
}

#banner {
  background: #161f2c;
  height: 850px;
  overflow: hidden;
  padding: 0;
  color: #fff;

  .bt {
    height: 55px;
    line-height: 29px;
  }

  header {
    position: absolute;
    width: 100%;
    z-index: 99999;
  }

  #banner-slider {
    .slick-list,
    .slick-track {
      height: 100%;
    }

    .text-center {
      opacity: 0;
    }
  }

  h1 {
    text-transform: uppercase;
  }

  h2 {
    margin-bottom: 20px;
  }

  .slide-bg {
    background-size: cover;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    width: 100%;
    height: 100%;
  }

  .text-slider {
    top: 41%;
    text-align: center;
  }

  .slick-dots {
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;

    li {
      display: inline-block;
      margin: 0 10px;

      &.slick-active {
        button {
          background: #fff;
        }
      }

      button {
        width: 76px;
        height: 6px;
        text-indent: 10000px;
        overflow: hidden;
        background: 0 0;
        background: rgba(255, 255, 255, 0.5);
        border: 1pt solid #fff;
        cursor: pointer;
        transition: 0.3s linear;
        border-radius: 0;
      }
    }
  }
}

.map-bg {
  background: url("../img/site/map.jpg") top center no-repeat #f7f7f7;
  background-size: cover;
}

#services {
  float: left;
  width: 100%;
  text-align: center;

  .slick-track {
    padding-top: 18px;
  }

  .services {
    width: 23%;
    min-height: 485px;
    height: auto;
    display: inline-block;
    background: #fff;
    margin: 13px 7px 0;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(158, 160, 176, 0.3);
    border-radius: 4px;

    &:hover {
      // box-shadow: 0 0 20px 0 rgba(158, 160, 176, 0.6) !important;

      .services-img {
        span {
          transform: scale(1);
          opacity: 0.7;
        }
      }
    }

    &:before {
      content: '';
      width: 75%;
      height: 1px;
      background: rgba(255,255,255,.5);
      left: 50%;
      position: absolute;
      top: -20px;
      transform: translate(-50%);
    }

    .services-img {
      overflow: hidden;
    }

    span {
      height: 160px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      cursor: pointer;
      float: left;
      transform: scale(1.1);
      line-height: 160px;
      transition: all .7s cubic-bezier(0.645, 0.045, 0.355, 1.000);

      .h1 {
        font-family: $black;
      }
    }

    p {
      padding: 20px 30px;
      width: auto;
      color: $gray_1;
    }
  }
}

#operations-facility {
  background: url("../img/site/bg.jpg") center/cover no-repeat;

  h1 {
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: "";
      width: 25%;
      height: 1px;
      background: #fff;
      position: absolute;
      top: -20px;
      transition: all 1.5s linear;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
    }
  }

  p {
    font-size: 19px;
    line-height: 28px;
    padding: 0;
    width: auto;
    font-family: $regular;
  }
}

#area {
  background: url("../img/site/bg-2.jpg") center/cover no-repeat;
  min-height: 360px;

  p {
    margin-bottom: 0;
    line-height: 23px;
  }

  .bt {
    margin-top: 45px;
  }

  h2 {
    font-family: $black;
    margin: 25px 0;
  }

  .area-fornecedor {
    h2 {
      color: $blue_2;
    }
  }

  .seja-consultor {
    h2 {
      margin-bottom: 14px;
      margin-top: 0;
    }
  }
}

#contact {
  background: url("../img/site/contact.jpg") center/cover no-repeat;

  strong {
    color: $gray_4;
    font-family: $black;
    margin-bottom: 14px;
    float: left;
    width: 100%;
  }

  label {
    color: $label;
  }

  b {
    span {
      color: $gray_4;
      font-size: 15px;
      font-family: $heavy;
    }
  }

  .numbers {
    a {
      color: #20405c;
      font-family: $heavy;
      float: left;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .contact-info {
    margin: 40px 0 80px;

    p {
      line-height: 20px;
    }
  }
}

footer {
  background: #20405c;
  color: #fff;
  padding: 30px 0 0;

  .container12 {
    padding: 0 66px;

    nav {
      float: right;

      a {
        color: #fff;
        font-family: $bold;
        font-size: 12px;
        text-transform: uppercase;
        margin: 0 40px;
        display: inline-block;

        &:hover {
          color: #3da0f4;
          transition: 0.5 linear;
        }
      }
    }
  }

  .copy {
    @extend .full;
    padding: 23px 0;
    margin-top: 23px;
    background: #0f283e;
    color: #576978;
    font-size: 12px;

    a {
      margin-right: 45px;
    }
  }
}

.slider-agencias {
  .slick-track {
    border-bottom: 1pt solid #ececec;
    padding-bottom: 35px;
  }

  .slick-list {
    padding-bottom: 3px;
  }

  .slick-slide {
    text-align: center;
  }

  a {
    position: relative;
    text-align: center;
    width: 120px;
    height: 90px;
    display: inline-block;

    &:before {
      transition: 1.300s linear;
    }

    &.active {
      &:before {
        content: '';
        position: absolute;
        bottom: -43px;
        width: 100%;
        height: 4px;
        background: #0d61a8;
      }

      img {
        &.hover {
          opacity: 1;
        }
      }
    }

    &:hover {
      img {
        opacity: 0;
      }

      .hover {
        opacity: 1;
      }
    }

    img {
      width: 150px;
      height: 92px;
      display: inline-block;
      transition: 0.300s ease-in;

      &.hover {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
}

.slider-agencias-text {
  float: left;
  width: 100%;
  position: relative;

  h4 {
    margin-top: 22px;
    margin-bottom: 15px;
  }

  .slick-dots {
    display: none !important;
  }
}

.slider-agencias {
  float: left;
  width: 100%;
}

#landing {
  form {
    input {
      margin-bottom: 20px !important;
    }
  }

  .landing-menu {
    line-height: 72px;

    a {
      font-family: $bold;
      font-size: 16px;
      color: #fff;
      transition: 0.5s linear;
      padding-left: 30px;

      &:hover {
        color: #75bee9;
      }
    }

    #menu-logged {
      font-size: 0;
      display: inline;
      padding-left: 12px;

      #menu-user {
        display: inline;

        .menu-user-vendor {
          .fa-user,
          .menu-user-name {
            vertical-align: initial !important;
          }

          .fa-caret-down {
            vertical-align: bottom !important;
          }
        }

        a {
          display: inline-block;
          line-height: 20px;
        }

        .fa-caret-down,
        .fa-user,
        .menu-user-name {
          vertical-align: super;
          display: inline-block;
          float: none;
        }

        .menu-user-img {
          width: 40px;
          height: 40px;
          display: inline-block;
          vertical-align: bottom;
          background-color: #fff;
          border-radius: 5px;
          margin-right: 5px;

          img {
            border-radius: 5px;
          }
        }

        ul {
          right: 0;
          padding: 0;
          width: 210px;
          right: 6px;
          top: 20px;

          li {
            height: 0;
            display: initial;

            a {
              font-family: $bold;
              color: #909090;
              padding: 15px 20px;
              min-height: 0;
              border-bottom: 2px solid #efefef;
            }

            &:last-child a {
              border: none;
            }
          }
        }
      }
    }
  }
}

#landing {
  transition: background-color 0.5s;
}

.menu-mobile {
  display: none;

  .container-mobile {
    display: inline-block;
    cursor: pointer;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 8px 0;
    transition: 0.4s;
  }

  .change-icon .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  .change-icon .bar2 {
    opacity: 0;
  }

  .change-icon .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }

  .sidenav {
    height: 100%;
    width: 80%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    transform: translate(-100%);
    will-change: transform;
    background-color: #20405c;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav a {
    padding: 0 0 0 30px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    height: 65px;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #277ada;
  }

  #main {
    transition: margin-left 0.5s;
    padding: 16px;
  }
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }

    .sidenav a {
      font-size: 18px;
    }
  }
}