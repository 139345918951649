.ict-show {
    .files {
        word-break: break-all;
    }

    .remove-file {
        color: #F24848;

        .fa-times {
            color: #F24848;
            vertical-align: baseline;
        }
    }

    .subtitle-paragraph {
        font-size: 20px;
        color: #5f5f5f;
        font-family: $heavy;
    }

    .file-upload-ict-show {
        .file-wrapper,
        .file-wrapper a,
        .upload-selecione:before,
        input {
            height: 27px;
        }

        .upload-selecione:before {
            line-height: 26px;
            border-radius: 3px;
        }

        .column1 {
            width: 73px;
        }
    }

    .ict-show-itens {
        span {
            font-family: $semibold;
            font-size: 14px;
            color: #909090;

            strong {
                color: #5f5f5f;
                font-family: $black;
            }
        }

        .status {
            span {
                color: #0d61a8;

                strong {
                    color: #0d61a8;
                }
            }
        }
    }

    .avaliation {
        hr {
            border-bottom: 2px solid #ebebeb;
        }

        .avaliation-img {
            display: inline-block;
            float: left;
            width: 130px;
            max-width: 130px;
            margin-right: 30px;

            .send-at {
                font-size: 11px;
                color: #5f5f5f;
                display: block;
                text-align: center;

                span {
                    margin-bottom: 5px;
                    display: inline-block;
                }
            }
        }

        .avaliation-text {
            h5 {
                color: #5f5f5f;
                font-family: $black;
                font-size: 17px;
            }

            p {
                white-space: pre-line;
                font-size: 14px;
            }
        }
    }
}

.ict {
    select {
        white-space: normal;
        padding-right: 30px;
    }

    .help-block {
        margin-bottom: 0;
    }

    .select.disabled {
        opacity: 0.2;
    }

    .select {
        z-index: 9999999;
    }

    .fa-times {
        color: #5f5f5f;
        cursor: pointer;
        transition: 500ms linear;
        vertical-align: text-bottom;
    }

    .file-wrapper {
        margin-bottom: 10px;
    }

    .alert-red {
        color: #ff0000;
        font-size: 13px;
    }

    .file-return {
        font-size: 14px;
        color: $blue;

        i {
            margin-left: 5px;
        }
    }

    .btn-send {
        padding: 6px 43px !important;
        height: auto;
        font-size: 12px;
        text-transform: uppercase;
    }

    .attachments-ict {
        i {
            vertical-align: middle;
            color: #5f5f5f;
            margin-right: 5px;
            margin-left: 20px;
        }

        &:first-child {
            margin-left: 0;
        }

        .paperclip {
            color: $blue;
            cursor: pointer;
            transition: 500ms linear;

            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }
    }
}

.ict-create {
    .search-spinner {
        top: 40px;
    }

    .multiselect__content-wrapper {
        top: 74px;
        z-index: 99999999;
    }

    .alert-red {
        margin-top: 10px;
    }

    .file-return {
        display: none !important;
    }
}