* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;
  box-sizing: border-box !important;
  text-rendering: optimizeLegibility!important;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale !important;
}

body,
html {
  width: 100%;
  height: 100%;
  display: block;
  font-family: $regular;
  -webkit-overflow-scrolling: touch;
}

html {
  overflow: hidden;
}

body {
  overflow: auto;
  min-height: 100%;
}

button {
  cursor: pointer;
}

a {

  transition: 0.300s ease-in;
  cursor: pointer !important;

  &.item-removing {
    i.fa-trash {

      font-size: 16px;

    }
  }

  &.item-editable-edit {
    i.fa-search {

      font-size: 16px;

    }
  }

  &.link-gray {
    color: $gray_1;
    font-size: 15px;

    &:hover {
      color: $label-color;
    }
  }

  &.link-blue {

    color: $blue_1;

    &:hover {
      color: $blue_2;
    }

  }

  &.link-clone {
    font-size: 15px;
    font-family: $black;

    &:hover {
      color: $blue_1 !important;
    }

    i {
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}

@-moz-document url-prefix() {
  * {
    font-weight: inherit !important;
  }

  html {
    .align-text-top {
      vertical-align: middle !important;
    }
  }
}

footer,
section {
  width: 100%;
  float: left;
  padding: 100px 0;
  position: relative;
}

footer {
  padding: 30px 0 0;

  &#footer-system {
    background: #0f283e;
    padding: 20px 0;

    .copy {
      color: #526473;
      font-size: 12px;
      font-family: $heavy;
    }
  }
}

ol,
ul {
  li {
    list-style: none;
    color: #909090;
  }
}

ol {
  li {
    ul {
      padding-left: 30px;
    }
  }
}

p {
  color: #909090;
}

hr {
  background: none;
  border-bottom: 1pt solid #fff;
  margin: 30px 0;
  width: 100%;
  float: left;
  clear: both;
}

.input-daterange {
  float: left;
  position: relative;
}

.slick-dots {
  margin-top: 20px;

  li {
    display: inline-block;

    &.slick-active {
      button {
        background: $blue;
      }
    }

    button {
      border: none !important;
      background: $label-color;
      width: 15px;
      height: 15px;
      text-indent: 1000px;
      overflow: hidden;
      border-radius: 100%;
      margin: 0 5px;
    }
  }
}

form {
  table {
    input,
    textarea {
      margin-bottom: 0 !important;
    }
  }
}

#status {
  h1 {
    font-family: $medium;
    font-size: 16px;
    margin-bottom: 24px;

    strong {
      font-size: 20px;
    }
  }

  .progress {
    width: 100%;
    float: left;
    height: 10px;
    border-radius: 5px;
    height: 6px;
    overflow: hidden;
    position: relative;
    background: #dedede;

    label {
      position: absolute;
      width: 100%;
      left: 0;
      background: url("../img/site/loading.jpg") repeat-x;
      height: 5px;
      transition: 0.500s ease;
    }
  }
}

.tab {
  display: none;
}

fieldset {
  &.warning-left {
    padding: 40px;
    position: relative;
    border: 1pt solid #eee;

    legend {
      background: #fff;
      height: 60px;
      line-height: 60px;
      position: absolute;
      left: -11px;
      font-size: 21px;
      top: 50%;
      margin-left: 1px;
      margin-top: -30px;
      color: $blue;
    }
  }
}
//Hamburguer menu

.hm {
  width: 18px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 7px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 14px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}

.open {
  header {
    #submenu {
      padding: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

body:not(.open) {
  .hm {
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: 2px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 14px;
        left: 2px;
      }
    }
  }
}

.white {
  background: #fff;
}

.vdp-datepicker {
  z-index: 1;

  input {
    margin-bottom: 0 !important;
  }

  &.disabled {
    opacity: 0.9;
  }
}

.item {
  @extend .full;
  z-index: 0;

  i {
    position: absolute;
    top: 0;
    border: 1pt solid #eee;
    height: $input-height;
    width: 47px;
    text-align: center;
    line-height: 46px;
    font-style: normal;
    color: $gray_1;
    z-index: 0;
  }

  .item-order {
    input {
      padding-left: 60px;
    }
  }

  input {
    background: none;
    z-index: 999999999;
    position: relative;
  }
}

.border-right {
  border-left: 2pt solid $gray_2;
}

.red {
  color: $error;
}

#cadastro-sucesso {
  background: url("../img/site/bg_.jpg") center center no-repeat;
  background-size: cover;

  .logo-success {
    margin-top: 40px;
    display: inline-block;
  }

  fieldset {
    border: 1px solid #fff;
  }

  h1 {
    color: $blue;
    padding-top: 90px;
    font-family: $regular;
  }

  h4 {
    color: #fff;
    padding: 45px;
    font-family: $regular;
  }

  form {
    label {
      color: #fff;
    }
  }

  p {
    margin-bottom: 20px;
    color: #fff;
  }
}

.label {
  color: $label-color;
  font-family: LatoWebHeavy;
}

[v-cloak] {
  display: none;
}

.super-disable {
  a,
  button,
  i,
  input,
  select,
  textarea {
    cursor: not-allowed !important;
  }
}

a,
button,
i,
input,
select,
textarea {
  &.super-disable {
    cursor: not-allowed !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.thumbs-red {
  color: $red;
}

.thumbs-green {
  color: $green;
}

table {
  .fa-search,
  .fa-star-o,
  .fa-times-circle {
    font-size: 18px;
    color: $gray_1;
  }

  .fa-star-active:before {
    font-family: 'FontAwesome';
    content: '\f005';
    color: #F3C402;
  }
}

.margin-right-icon {
  margin-right: 30px;
}

button {
  border: none !important;
  background-color: transparent;
}

.scroll-auto {
  overflow: auto;
  max-height: 320px;
}

.min-height-100 {
  min-height: 100%;
}

.height-100 {
  min-height: 100%;
  height: 100%;
}

.requisicao-compra .link {
  font-size: 14px;
}

.login-form {
  hr {
    border-bottom: 1pt solid rgba(255, 255, 255, 0.22);
    margin: 20px 0;
  }

  a.link {
    color: $blue_3 !important;

    &:hover {
      color: $blue_2 !important;
    }
  }
}

textarea {
  resize: vertical;
  min-height: 140px;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
}

.item {
  &.money-position {
    i {
      border: 0px;
      // border-right: 1px solid #ddd;
      z-index: 90;
      top: 24px;
      left: 24px;
    }

    input {
      padding-left: 60px;
      background-color: #fafafa;
      z-index: 9;
    }
  }
}

.max-height-450 {
  max-height: 300px !important;
  overflow-y: auto !important;
  margin-bottom: 40px;

  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0,0,0,.1);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0,0,0,.6);
  }
}

#cadastro-sucesso,
#licitacoes,
.access-config,
.detalhes-fornecedor {
  .help-block {
    float: left;
    width: 100%;
    clear: both;
    // margin-top: -36px !important;
  }
}

#licitacoes {
  .simple-image-upload {
    .help-block {
      margin-top: 0 !important;
    }
  }
}

#cadastro-sucesso {
  .msg-error-register {
    .help-block {
      margin-top: 0 !important;
    }
  }
}

.detalhes-fornecedor {
  .file-upload {
    .help-block {
      margin-top: -10px !important;
    }
  }
}

#show-procurement {
  .windows8 {
    position: absolute;
    width: 78px;
    height: 78px;
    margin: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

#contact .selected-flag {
  height: 45px;
}

.my-projects-switch {
  label {
    &:last-child {
      float: right;
      width: 97px;
      margin-left: 10px;
      color: #cecece;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.label-align-middle {
  width: auto;
  display: inline-block;
  float: none;
}

.span-align-middle {
  vertical-align: super;
}

.notifications {
  .notifications-ul {
    width: 450px;
    z-index: 9999999999999;
    margin-left: -186px;

    .notifications-title {
      font-family: $bold;
      font-size: 12px;
      color: #313131;
      text-transform: uppercase;
      background-color: #f7f7f7;
      padding: 10px 30px;
      border: 1px solid #eee;
    }

    .notifications-date {
      display: block;
      font-size: 10px;
      color: #909090;
      font-family: $medium;

      i {
        margin-right: 10px;
      }
    }

    #listNotifications {
      width: 100%;

      li {
        border: none;

        &.no-notifications,
        a {
          padding: 10px 30px;
          font-family: $bold;
          font-size: 13px;
          color: #313131;
          transition: 0.5s linear;

          &:hover {
            color: #0d61a8;
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}

.error-help-block {
  margin-bottom: 15px;
}

#pagenotfound {
  height: 100%;
  width: 100%;
  position: relative;

  .page-error-content {
    p {
      margin-top: 20px;
      font-size: 16px;
      font-family: $medium;
      color: #14334e;

      a {
        color: $blue;

        &:hover {
          color: $blue_4;
        }
      }
    }

    .bt.blue2 {
      background-color: #0F3A60;

      &:hover {
        border-color: $blue !important;
        background-color: $blue;
      }
    }
  }
}

.processes-alert {
  text-align: left;
  margin-bottom: 20px;

  .alert {
    color: #0d61a8;
    font-size: 16px;
    position: relative;
    border: 2px solid #f1f1f1;
    box-sizing: border-box;
    padding: 15px 0;

    .column1 {
      width: 30px !important;
    }

    i {
      z-index: 9;
      font-size: 25px;
      border-top: 8px solid #fff;
      border-bottom: 5px solid #fff;
      position: absolute;
      left: -13px;
      top: 50%;
      transform: translate(0%, -50%);
    }

    span {
      vertical-align: text-bottom;
      margin-bottom: 2px;
      display: inline-block;
      font-family: $semibold;
    }

    a {
      font-family: $heavy;
      text-decoration: underline;
      color: #0d61a8;
      cursor: pointer;
    }
  }
}

.opacity10 {
  opacity: 0.1;
}

#landing {

  .processes-alert {

    margin-bottom: 40px;

    .alert {

      i {
        border-top: 8px solid #f7f7f7;
        border-bottom: 5px solid #f7f7f7;
      }
    }
  }
}
