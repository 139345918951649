$fa:        'FontAwesome';
$thin:      'LatoWebThin';
$bold:      'LatoWebBold';
$heavy:     'LatoWebHeavy';
$black:     'LatoWebBlack';
$light:     'LatoWebLight';
$medium:    'LatoWebMedium';
$regular:   'LatoWeb';
$hairLine:  'LatoWebHairline';
$semibold:  'LatoWebSemibold';

$blue:      #0d61a8;
$blue_1:    #103c62;
$blue_2:    #20cefa;
$blue_3:    #3da0f4;
$blue_4:    #284f71;
$gray:      #9d9d9d;
$gray_1:    #686868;
$gray_2:    #f0f0f0; //Cinza mais claro;
$gray_3:    #2b2b2b; //borda dos inputs;
$gray_4:    #404242;
$gray_5:    #a7a7a7;
$gray_6:    #d4d4d4;
$gray_7:    #d7d7d7;
$label:     #696969;
$red:       #f24949;
$green:     #38c0a6;



h1, .h1, h2, .h2, h3, .h3, h5, .h5 {

  float: left;
  width: 100%;

  small {
    font-size: 12px;
    bottom: 0;
  }
}

h1, .h1 {

  margin-bottom: 40px;
  font-family: $semibold;
  font-weight: 100;

}
//
// h2, .h2 {
//
//   font-size: 30px;
//   margin-bottom: 60px;
//   font-family: $regular;
//   font-weight: normal;
//
// }
//
// h3, .h3 {
//
//   font-size: 30px;
//
// }

.title {

  font-size: 25px;
  font-family: $semibold !important;
  padding-bottom: 40px;
  // margin-bottom: 20px;
  @extend .full;
  color: $blue;
  border-bottom: 1pt solid #f1f1f1;

}

h4, .h4 {

  font-size: 20px;
  font-family: $bold;

}

p {

  font-size: 16px;
  float: left;
  color: $gray;
  width: 100%;

}

.white, .gray {

  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {

    color: $blue;

  }

}

.gray {

  background: #f7f7f7;

}

.dark {

  background: #133859;

  h1, .h1, h2, .h2, h3, .h3, p {

    color: #fff;

  }

}

.color-label{
  color: #20405c !important;
}

.dash-title {
  color: #5e5e5e;
  font-family: $bold;
  font-size: 13px;
  line-height: 22px;
}

.text-muted {
  font-weight: 400;
  font-size: 12px;
  color: $gray;
}

.text-sm {
  font-size: 12px !important;
}

.text-md {
  font-size: 14px !important;
}

.text-lg {
  font-size: 16px !important;
}
