.trash-button {
    a {
        font-size: 30px;
    }
}

.btn-remove {
    cursor: pointer;
    transition: 0.3s linear;

    &:hover {
        color: #ff0000;
    }
}

.bt:not(.iti-flag) {
    border: 1px solid #fff;
    padding: 14px 30px !important;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    font-family: $bold;
    text-align: center;
    float: none;
    cursor: pointer;
    transition: 0.300s ease-in;
    margin-top: 40px;
    // min-height: 46px;
    &.default {
        border-color: $primary;
        background: $primary;
        color: #fff !important;

        &:hover {
            border-color: $gray-2;
            background: $gray-1;
        }
    }

    &.gray {
        background: #aaaaaa;
        border-color: #aaa;

        &:hover {
            background: $gray_6;
            border-color: $gray_6;
            color: #fff;
        }
    }

    &.borders {
        border-color: #82accf;
        color: $blue;
    }

    &:hover {
        background: #ffffff;
        color: #20405c;
    }

    &.bg {
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(255, 255, 255, 0);
        color: #20405c;

        &:hover {
            background: #fff;
        }
    }

    &.blue {
        background: #20cefa;
        border: 1px solid rgba(255, 255, 255, 0);
        color: #fff;

        &:hover {
            color: #20405c;
        }
    }

    &.blue2 {
        background: #20405c;
        border-color: #20405c;

        &:hover {
            border-color: #20405c !important;
            background: $blue_4;
            color: #fff;
        }
    }

    &.blue3 {
        &:hover {
            border-color: #0d61a8 !important;
            background: #0d61a8;
            color: #fff;
        }
    }

    &.min {
        // padding: 10px 30px;
        font-size: 12px;
    }

    &.small {
        font-size: 12px;
        padding: 5px 14px !important;
    }

    &.big {
        padding-top: 19px;
        padding-bottom: 19px;
    }

    .fa-search {
        color: white;
    }
}

.link {
    color: #ffffff;
    font-size: 12px;
    font-family: $bold;

    &.success {
        background-color: #fff;
        border-radius: 3px;
        padding: 8px 20px;
        color: $success;
        border: 1px solid $success !important;

        &.is-active,
        &:hover {
            background: $success;
            color: #fff;
        }
    }

    &.error {
        border-radius: 3px;
        padding: 8px 20px;
        color: $error;
        border: 1px solid $error;

        &.is-active,
        &:hover {
            background: $error;
            color: #fff;
        }
    }

    &:hover {
        color: #03b9eb;
    }

    &.blue {
        color: $blue !important;

        &:hover {
            color: $blue_3;
        }
    }
}

.icon-blue {
    color: $blue !important;
    font-size: inherit !important;
}

.actions {
    a {
        &:hover {
            color: #0d61a8 !important;
        }
    }

    .fa {
        cursor: pointer;
    }

    .fa-trash {
        color: #dd2424;
    }

    .fa-no-color {
        color: $gray_1 !important;
    }

    .fa-check-square-o {
        color: #75CE7A;
    }

    .fa-times {
        color: #dd2424;
    }

    .fa-plus-circle {
        color: #20405c;
    }

    .tabs-step .fa-check-square-o {
        color: #adadad;
    }

    .fa-times-circle {
        &:hover {
            color: #de2d2d !important;
        }
    }
}

.bt {
    &.btn-create {
        padding: 5px 34px !important;
        height: auto;
    }
}

#escolha-modulo {
    .icomoon-procurement:before {
        color: #20405C;
    }

    a.modulo:hover .icomoon-procurement:before {
        color: #fff;
    }
}

.icomoon-procurement {
    vertical-align: text-bottom;
    font-size: 16px;
}

.icon-requests.icomoon-procurement:before {
    font-size: 24px;
    color: #20405c;
}

.fa-check-square-o {
    &:hover {
        color: #27ae60;
    }
}

form {
    .bt.btn-send {
        padding: 6px 43px !important;
        height: auto !important;
        font-size: 12px !important;
        text-transform: uppercase !important;
    }
}