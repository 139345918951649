.logo {
  float: left;
  width: auto;
  text-align: left;
  margin-bottom: 30px;
}

.margin-left {
  margin: 0 !important;
}

.input-daterange {
  .date {
    width: 48%;

    &:nth-child(2) {
      margin-left: 13px!important;
    }
  }
}

.vendor-process-opportunities {
  .lastitems {
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
  
  .form .bt:not(.iti-flag).secondary.min, form .bt:not(.iti-flag).secondary.min {
    line-height: 10px;
  }

  .licitacao-detail {
    .list-content {
      padding: 0;
    }
    
    .btn-participate {
      margin: 20px 0px !important;
      padding: 0px !important;
    }

    .process-informations {
      padding: 0px 10px;
    }
  }

  .submenu-logo {
    text-align: center;

    a {
      float: none;
      display: inline-block;
    }
  }

  .datepicker {
    z-index: 9999 !important;
  }

  .licitacoes-process {
    h1 {
      margin-bottom: 30px !important;
    }

    form {
      input {
        margin-bottom: 20px !important;
      }

      .input-daterange .date {
        width: 100%;
        margin: 0 0 10px !important;
      }
    }

    .processes-alert {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }

  .alert-warning {
    .column1 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(0%, -50%);
    }

    .column11 {
      br {
        display: none;
      }

      span {
        padding: 10px 20px;
        text-align: justify;
      }
    }
  }

  .licitacao-detail {
    .list-content {
      .tabs-content {
        .column6 {
          width: 100% !important;
          margin-top: 20px;

          ul {
            width: 88%;
          }
        }

        .column10 {
          width: 88%;
        }
      }
    }
  }
}

.slider-agencias a {
  float: none;
  display: inline-block;
}

#landing {
  #banner {
    h1 {
      font-size: 30px;
      margin-bottom: 20px !important;
    }

    .logo {
      img {
        width: 150px;
      }
    }

    .column3 {
      width: auto;
      display: inline-block;
    }

    .column5 {
      vertical-align: middle;
      display: inline-flex;
    }

    .landing-menu {
      width: auto;
      display: inline-block;
      text-align: left;
      float: right !important;

      .menu-user-name {
        width: auto;
        max-width: 100px;
        overflow: hidden;
        height: 20px;
      }
    }
  }
}

#operations-facility {
  padding: 40px 0;
}

#services {
  .services {
    height: auto !important;
  }
}

.contact-info {
  div {
    padding-bottom: 30px;
    padding-left: 0 !important;
  }
}

#contact {
  input {
    width: 100% !important;
  }
}

#landing {
  .landing-menu {
    #menu-logged {
      a {
        padding-left: 10px;
      }
    }

    .menu-user-name {
      max-width: none !important;
    }
  }

  footer {
    text-align: center;

    .copy {
      a {
        float: none !important;
        margin: 20px 0 0;
        display: inline-block;
        text-align: center;
      }
    }
  }

  #banner {
    height: 100%;
    min-height: 550px;
    padding-top: 0;
  }

  .intl-tel-input {
    width: 100%;
  }

  section {
    padding: 30px 0;
  }

  #operations-facility {
    h1 {
      margin-top: 30px;
    }

    .bt {
      margin-top: 0;
    }
  }

  #area {
    padding: 0 0 30px;
  }

  #contact {
    h1 {
      margin-bottom: 0;
    }
  }

  .contact-info {
    margin-bottom: 0 !important;
  }

  .seja-consultor {
    margin-top: 30px;
  }

  .table-process {
    .bt {
      margin-top: 0;
    }
  }

  .map-bg {
    h1 {
      margin-bottom: 10px;
    }
  }

  .processes-alert {
    .alert-warning {
      .column1 {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(0%, -50%);
      }

      .column11 {
        br {
          display: none;
        }

        span {
          padding: 10px 20px;
          text-align: justify;
        }
      }
    }
  }
}

.hidden-mobile {
  display: none !important;
}

.show-mobile {
  display: block !important;
}

#landing {
  #services {
    .services {
      min-height: 400px !important;
    }
  }
}

table.footable > tbody > tr > td.footable-sortable,
table.footable > tbody > tr > th.footable-sortable,
table.footable > tfoot > tr > td.footable-sortable,
table.footable > tfoot > tr > th.footable-sortable,
table.footable > thead > tr > td.footable-sortable,
table.footable > thead > tr > th.footable-sortable {
  padding-right: 0 !important;
}

#landing .table thead th {
  padding: 10px 3px !important;
}

.tab tbody tr td,
.tab thead th  {
  padding: 5px !important;
}

.table tbody tr td {
  padding: 15px 10px !important;
}

.tabs .tabs-nav li {
  width: 50%;
}

.table tbody .footable-detail-row > td {
  padding: 0px 10px !important;
}

.tabs .tabs-nav li a.tab-selected {
  border: 2px solid #e7eaec !important;
}
