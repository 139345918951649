@media print {
  a,
  button,
  footer,
  header,
  input,
  select,
  textarea,
  label,
  .print-none,
  .form-group,
  .file-wrapper {
    display: none !important;
  }

  .detalhes-fornecedor {
    #recomendacoes {
      li {
       padding: 0px;
     }
    }
  }

  main {
    padding: 0px !important;
  }

  body {
    page-break-after: auto;
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-inside: avoid;
    page-break-after: auto;
    display: table-header-group;
    display: table-footer-group;
  }

  table {
    display: block !important;
  }

  .full {
    padding: 0;
  }

  .scroll-auto {
    max-height: 100%;
  }

  * {
    -webkit-print-color-adjust: exact;
    padding: 3 !important;
  }

  @page {
    margin: 0.5cm;
  }

  section {
    padding: 20px 0;
  }

  body,
  html {
    overflow: auto;
  }

  strong, b {
    font-weight: 500;
  }
}
